import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import theme from '../../services/theme-service';
import { Breadcrumb, Title } from './components';
import colorContext from '../../Context/colorContext';
import { getGovernorData } from '../../services/adapters/governador_wp';

interface GovernorData {
  author: number;
  content: {
    protected: boolean
    rendered: string
  }
  date: string
  date_gmt: string
  title: {
    rendered: string
  }
  status: string
  excerpt: {
    rendered: string
    protected: boolean
  }
}

export default function GovernorInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [loading, setLoading] = useState(false);
  const [governor, setGovernor] = useState<GovernorData | null>(null);
  const useStyles = makeStyles({
    main: {
      width: '100%',
      minHeight: '100vh',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      padding: '0px 5px',
    },
    box: {
      display: 'flex',
      maxWidth: '1040px',
      width: '100%',
      flexDirection: 'column',
      marginTop: '34px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      margin: 5,
    },
    content: {
      width: '100%',
      margin: '20px 20px',
      wordWrap: 'break-word',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        const { data } = await getGovernorData();

        setGovernor(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  const renderContent = () => {
    if (governor) {
      return (
        <Box
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: governor?.content?.rendered }}
        />
      );
    }
    return (
      <h1>Sem conteúdo</h1>
    );
  };

  return (
    <>
      <Breadcrumb slug="Agenda do Governador" />
      <Title title="Agenda do Governador" subtitle="" />
      <Box className={classes.main}>
        {loading
          ? (
            <Box
              marginBottom="60px"
              flex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )
          : (
            <Box className={classes.box}>
              {renderContent()}
            </Box>
          )}
      </Box>
    </>
  );
}
