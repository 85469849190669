import React from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getOrder } from '../../../../utils/managerContents';

interface Props {
  refCategory: React.MutableRefObject<null>;
  refService: React.MutableRefObject<null>;
  refEstatistic: React.MutableRefObject<null>;
  refAttendance: React.MutableRefObject<null>;
  refApps: React.MutableRefObject<null>;
  refNoticias: React.MutableRefObject<null>;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    background: '#FFFFFF',
  },
  boxRight: {
    width: 2,
    height: 8,
    backgroundColor: theme.palette.secondary.main,
    marginRight: 5,
  },
  text: {
    cursor: 'pointer',
    fontWeight: 700,

    fontSize: '14px',

  },
  icon: {
    color: theme.palette.primary.main,
    width: 64,
    height: 56,
    padding: 10,
    marginRight: 40,
  },
}));

export default function Options({
  refCategory,
  refService,
  refEstatistic,
  refAttendance,
  refApps,
  refNoticias,
}: Props): JSX.Element {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:935px)');

  const Scroll = (ref: React.MutableRefObject<any>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Box style={{ display: matches ? 'flex' : 'none' }} className={classes.main}>
      <Box
        hidden
        display="flex"
        maxWidth="1052px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        {getOrder('categorias') !== -1 && (
        <Box
          onClick={(): void => Scroll(refCategory)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('categorias'),
          }}
        >
          <Typography className={classes.text}>CATEGORIAS</Typography>
        </Box>
        )}
        {getOrder('servicos_destaque') !== -1 && (
        <Box
          onClick={(): void => Scroll(refService)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('servicos_destaque'),
          }}
        >
          <Typography className={classes.text}>SERVIÇOS</Typography>
        </Box>
        )}
        {getOrder('indicadores') !== -1 && (
        <Box
          onClick={(): void => Scroll(refEstatistic)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('indicadores'),
          }}
        >
          <Typography className={classes.text}>INDICADORES</Typography>
        </Box>
        )}
        {getOrder('canais_atendimento') !== -1 && (
        <Box
          onClick={(): void => Scroll(refAttendance)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('canais_atendimento'),
          }}
        >
          <Typography className={classes.text}>ATENDIMENTOS</Typography>
        </Box>
        )}

        {getOrder('noticias') !== -1 && (
        <Box
          onClick={(): void => Scroll(refNoticias)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('noticias'),
          }}
        >
          <Typography className={classes.text}>NOTÍCIAS</Typography>
        </Box>
        )}
        {/* {getOrder('app_governo') !== -1 && (
        <Box
          onClick={(): void => Scroll(refApps)}
          display="flex"
          alignItems="center"
          style={{
            order: getOrder('app_governo'),
          }}
        >
          <Typography className={classes.text}>APPS</Typography>
        </Box>
        )} */}
      </Box>
    </Box>
  );
}
