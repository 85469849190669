/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { MessageTwoTone, Close } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { FileObject } from 'material-ui-dropzone';
import { format } from 'date-fns';
import {
  anexarArquivoResposta, atendimentoPorId, enviarMensagem, mensagemLida,
} from '../../services/atendimentos';
import {
  Breadcrumb,
  Chat,
  DetailChat,
  ModalSendFiles,
  OrganSolicitation,
  SendMessage,
  Video,
} from './components';
// eslint-disable-next-line import/extensions
import { UserData } from '../../services/user';
import NavBarLink from '../QuickAccess/components/NavBarLink';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 400,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxVideoChat: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    padding: 5,
    height: 750,
  },
  buttonSwitch: {
    marginTop: 20,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
  },
  button: {
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 0,
    borderRadius: 0,
    border: '1px solid #0F6FB7',
    backgroundColor: '#0F6FB7',
    '&:hover': {
      backgroundColor: '#2985cc',
    },
  },
}));

interface Params {
  id: string;
}

export interface AttendanceOnlineI {
  anexos: {
    arquivo: string;
    created_at: string;
    descricao: string;
    id: number;
    titulo: string;
    topico: string;
  }[];
  conteudo: string;
  created_at: string;
  tipo: 'Video' | 'Chat';
  guiche: {
    ativo: boolean;
    created_at: string;
    id: number;
    ordem: number;
    setor: number;
    tipo: string;
    updated_at: string;
  };
  id: string;
  orgao_responsavel: string;
  respostas: {
    anexo: boolean;
    anexos: {
      arquivo: string;
      created_at: string;
      descricao: string;
      id: number;
      titulo: string;
      topico: string;
    }[];
    conteudo: string;
    created_at: string;
    id: number;
    lido: boolean;
    updated_at: string;
    user: {
      first_name: string;
      id: number;
      last_name: string;
      username: string;
    }
  }[];
  servico: {
    acesso_externo: boolean;
    agendavel: boolean;
    ativo: boolean;
    created_at: string;
    custo: string;
    descricao: string;
    online: boolean;
    publico: string;
    setor: number;
    slug: string;
    tema: number;
    tempo: number;
    tempo_online: number;
    tempo_total: number;
    tipo_tempo: string;
    titulo: string;
    updated_at: string;
    url_externo: string | null;
    user: number;
  }
  status: string;
  titulo: string;
  unidade: {
    ativo: boolean;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string | null;
    created_at: string;
    endereco: string;
    nome: string;
    orgao: number;
    slug_unidade: string;
    source: string;
    updated_at: string;
    user: number;
  };
  updated_at: string;
  user: {
    first_name: string;
    id: string;
    last_name: string;
    username: string;
  }
}

export default function AttendanceOnlineChat(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const params: Params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [hideChat, setHideChat] = useState<boolean>(true);
  const showVideo = true;
  const [hideInfo, setHideInfo] = useState<boolean>(false);
  const [chatService, setChatService] = useState<AttendanceOnlineI>();
  const matches = useMediaQuery('(max-width:928px)');
  const matchesSm = useMediaQuery('(max-width:576px)');

  const [showSendArquive, setShowSendArquive] = useState<boolean>(false);
  const [files, setFiles] = useState <FileObject[] >([]);

  const [loadingSendFiles, setLoadingSendFiles] = useState<boolean>(false);

  const connect = () => {
    if (!atob(process.env.REACT_APP_WEB_SOCKET)) {
      return;
    }
    const ws = new WebSocket(`${atob(process.env.REACT_APP_WEB_SOCKET)}/ws/${params.id}`);

    ws.onmessage = (e) => {
      const dataReceive = JSON.parse(e.data);
      setChatService((chat): any => {
        const newchat: any = {
          ...chat,
          respostas: chat?.respostas.concat(dataReceive),
        };
        console.log(newchat);
        setChatService(newchat);
      });
    };

    ws.onclose = (e) => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(() => {
        connect();
      }, 1000);
    };

    ws.onerror = (err) => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      ws.close();
    };

    ws.onopen = (e) => {
      console.log('connect');
    };
  };

  const getAttendanceById = async (token: string, id: string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await atendimentoPorId(token, id);
      setChatService(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const sendMessage = async (text: string): Promise<any> => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token && text) {
      try {
        const { data } = await enviarMensagem(token, params.id, text, false);
        const newchat: any = {
          ...chatService,
          respostas: chatService?.respostas.concat(data),
        };
        setChatService(newchat);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const sendArquive = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    setLoadingSendFiles(true);
    if (token) {
      try {
        const { data } = await enviarMensagem(token, params.id, 'Segue anexo: ', true);
        const newResposta: any = data;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileUpload of files) {
          // eslint-disable-next-line no-await-in-loop
          const response = await anexarArquivoResposta(token, fileUpload.file.name, fileUpload.file.type, fileUpload.file, data.id);
          newResposta.anexos.push(response.data);
        }
        const newchat: any = {
          ...chatService,
          respostas: chatService?.respostas.concat(newResposta),
        };
        setChatService(newchat);
        setShowSendArquive(false);
        setLoadingSendFiles(false);
      } catch (err) {
        setLoadingSendFiles(false);
      }
    }
    setLoadingSendFiles(false);
  };

  const readMessage = async (): Promise<any> => {
    const dataStorage: string | null = getCookie('gov_user_data');
    const token: string | null = getCookie('gov_access_token_sso');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (chatService && userData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const resposta of chatService.respostas) {
        const { lido, user, id } = resposta;
        if (!lido && user.username !== userData.cpf && token) {
          // eslint-disable-next-line no-await-in-loop
          await mensagemLida(token, id);
        }
      }
    }
  };

  useEffect(() => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      getAttendanceById(token, params.id);
    } else {
      history.replace('/');
    }
  }, [params.id]);

  useEffect(() => {
    if (matchesSm) {
      setHideChat(false);
    } else {
      setHideChat(true);
    }
  }, [matchesSm]);

  useEffect(() => {
    connect();
  }, [params.id]);

  useEffect(() => {
    readMessage();
  }, [chatService]);

  const handleRemoveChat = () => {
    setHideChat(!hideChat);
  };

  const handleHideInfo = () => {
    setHideInfo(!hideInfo);
  };

  return (
    <>
      <Breadcrumb slug="Atendimento online" />
      <NavBarLink />
      <Box className={classes.main}>

        {chatService && !loading && (
        <>
          <ModalSendFiles
            open={showSendArquive}
            handleClose={(): void => { setShowSendArquive(false); }}
            files={files}
            setFiles={setFiles}
            sendArquive={sendArquive}
            loadingFiles={loadingSendFiles}
          />
          <Box maxWidth="1052px" padding="20px" display="flex" alignItems="center" width="100%" flexWrap="wrap">
            <Box width="100%" padding={matches ? '0px' : '10px'}>
              <OrganSolicitation
                organName={chatService.unidade.nome}
                status={chatService.status}
                buttonHideInfoChat={handleHideInfo}
                hideInfo={hideInfo}
              />
              {hideInfo ? (
                <DetailChat
                  serviceName={chatService.servico.titulo}
                  dateSolicitation={format(new Date(chatService.created_at), 'dd/MM/yyyy')}
                  scheduleSolicitation={format(new Date(chatService.created_at), 'HH:mm')}
                />
              ) : ''}
            </Box>

            <Box
              className={classes.buttonSwitch}
            >
              {chatService.tipo === 'Video' && chatService.status === 'Em Atendimento' ? (
                <Button
                  onClick={() => handleRemoveChat()}
                  className={classes.button}
                  style={{
                    color: '#fffff',
                    backgroundColor: '#0F6FB7',
                    display: showVideo ? 'visible' : 'none',
                  }}
                >
                  {hideChat ? <Close /> : <MessageTwoTone />}
                </Button>
              ) : ''}
            </Box>

            <Box className={classes.boxVideoChat} style={{ display: (matchesSm) ? 'block' : 'flex' }}>
              {chatService.tipo === 'Video' && chatService.status === 'Em Atendimento' ? <Video roomName={params.id} hideChat={hideChat} showVideo={showVideo} /> : ''}
              {hideChat ? (
                <Box style={(matchesSm && chatService.tipo === 'Video' && chatService.status === 'Em Atendimento') ? {
                  width: '100%', position: 'relative', top: -650, backgroundColor: '#fff',
                } : { width: '100%' }}
                >
                  <Chat
                    chatService={chatService}
                  />
                  {chatService.status === 'Em Atendimento' && (
                  <SendMessage
                    showSendArquive={(): void => setShowSendArquive(true)}
                    sendMessage={sendMessage}
                  />
                  )}
                </Box>
              ) : ''}
            </Box>
          </Box>
        </>
        )}
        {loading && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
        )}
      </Box>
    </>
  );
}
