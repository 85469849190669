/* eslint-disable import/prefer-default-export */
export const setCookie = (cname: string, cvalue: string, exdays = 7): void => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;

  // Encode both the name and value in Base64
  const encodedValue = btoa(cvalue);
  document.cookie = `${cname}=${encodedValue};${expires};path=/`;
};
