/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line import/no-cycle
import axios from 'axios';
import { News } from '../../Pages/News';
import { apinoticiasExterna } from '../api';

interface NewsData {
  _links: {
    'wp:featuredmedia': {
      href: string;
    }[];
    author: {
      href: string;
    }[];

  };
  content: {
    rendered: string;
  };
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  categories: number[];
  modified: string;
  excerpt: {
    rendered: string;

  };
}

const getImagemDestaque = async (item: NewsData): Promise<string> => {
  try {
    if (item?._links['wp:featuredmedia']?.length > 0) {
      const img_api = item._links['wp:featuredmedia'][0].href;
      const { data } = await axios.get(img_api);
      return data?.media_details?.sizes?.full?.source_url || '';
    }
  } catch (error) {
    console.log(error);
  }

  return '';
};

const getCategoriaInfo = async (item: NewsData): Promise<{
  id?: number;
  name: string;
}> => {
  try {
    if (item?.categories?.length > 0) {
      const { data } = await apinoticiasExterna.get(`${atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER)}/categories/${item.categories[0]}`);
      return {
        name: data?.name,
        id: data?.id,
      } || {
        name: '',
      };
    }
  } catch (error) {
    console.log(error);
  }

  return {
    name: '',
  };
};

const getAutorNome = async (item: NewsData): Promise<string> => {
  try {
    if (item?._links?.author?.length > 0) {
      const { data } = await apinoticiasExterna.get(`${item._links.author[0].href}`);
      return data?.name || '';
    }
  } catch (error) {
    console.log(error);
  }

  return '';
};

const adapterNewsWPV2 = async (newsData: any) => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    const imgPromises = newsData.map((item) => getImagemDestaque(item));
    const imgUrls = await Promise.all(imgPromises);

    const categoriesPromises = newsData.map((item) => getCategoriaInfo(item));
    const categoriesList = await Promise.all(categoriesPromises);

    const autoresPromises = newsData.map((item) => getAutorNome(item));
    const autoresList = await Promise.all(autoresPromises);

    const newsAdapter = {
      count: newsData.length || 0,
      current: 1,
      next: 2,
      previous: 0,
      results: newsData.map((item, index) => ({
        autor_imagem: '',
        categoria_icone: '',
        categoria_slug: '',
        categoria_titulo: categoriesList[index].name || '',
        conteudo: item.content.rendered,
        created_at: item.modified,
        descricao_imagem: '',
        descricao_video: '',
        id: item.id,
        imagem_destaque_url: imgUrls[index],
        orgao_nome: '',
        orgao_sigla: '',
        orgao_slug: '',
        publicado: true,
        resumo: item.excerpt.rendered,
        slug: item.slug,
        subtopico_icone: '',
        subtopico_slug: '',
        subtopico_titulo: categoriesList[index].name || '',
        titulo: item.title.rendered,
        updated_at: item.modified,
        youtube_videoId: '',
        autor_nome: autoresList[index],
        categoria_id: categoriesList[index].id,
      })),

      total_pages: 10,
    };
    return newsAdapter as News;
  }

  return newsData;
};

export default adapterNewsWPV2;
