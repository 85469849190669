import React, { useContext } from 'react';
import {
  Box, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import colorContext from '../../../../Context/colorContext';

interface Props {
  author: string;
  created: string;
}

export default function Author({
  author,
  created,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxMain: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
      paddingBottom: 37,
    },
    main: {
      width: '100%',
      display: 'flex',
      maxWidth: '1040px',
      flexDirection: 'column',
    },
    text: {
      color: colors.textAccentColor,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      marginBottom: 8,
      '& > span': {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
      },
    },
    time: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      color: colors.textAccentColor,
      '& > span': {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
      },
    },
    iconColor: {
      color: colors.textAccentColor,
    },
  }));
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:660px)');
  return (
    <Box className={classes.boxMain}>
      <Box className={classes.main}>
        {author?.length > 0 && (
        <Typography className={classes.text}>
          <span>Autor:</span>
          {author}
        </Typography>
)}

        <Typography className={classes.time}>
          <span>Publicado em:</span>
          {' '}
          {format(new Date(created), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
        </Typography>
      </Box>
    </Box>
  );
}
