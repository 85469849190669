import React, {
   useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, makeStyles, CircularProgress,
} from '@material-ui/core';
import theme from '../../../../services/theme-service';
import colorContext from '../../../../Context/colorContext';
import { Breadcrumb, Title } from './componets';
import { getPages } from '../../../../services/noticias';


interface Params {
  slug: string;
}

interface Props {
  conteudo: string;
  id: string;
  descricao?: string;
  slug: string;
  titulo: string;
}

const Pages = (): JSX.Element => {
  const { colors } = useContext(colorContext);
  const [pageData, setPageData] = useState<Props>();
  const [loading, setLoading] = useState(true);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: '100vh',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      padding: '0px 5px',
    },
    textTitleInitial: {
      position: 'relative',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      padding: '10px 0px 0px 3px',
      fontWeight: 'bold',
      color: colors.textSecondary,
      left: 8,
      fontSize: theme.typography.pxToRem(36),
      '&::after': {
        content: '""',
        display: 'block',
        width: 4,
        height: 30,
        backgroundColor: colors.accentColor,
        position: 'absolute',
        bottom: 11,
        marginLeft: -14,
        left: 5,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(28),
        '&::after': {
          bottom: 4,
        },
      },
    },
    box: {
      display: 'flex',
      maxWidth: '1040px',
      width: '100%',
      flexDirection: 'column',
      marginTop: '34px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      margin: 5,
    },
    content: {
      width: '100%',
      margin: '20px 20px',
      wordWrap: 'break-word',
      '& img': {  
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%', 
          height: 'auto',  
          display: 'block', 
          margin: '0 auto'
        },    
      }
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();

  useEffect(() => {
    async function handlePage() {
      try {
        const { data } = await getPages(params.slug);
        setPageData(data.results[0]);

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    handlePage();
  }, [params.slug]);

  // useEffect(() => {
  //   const trackPages = () => {
  //     (window as any).gtag('event', 'screen_view', {
  //       app_name: 'cms',
  //       screen_name: 'cms',
  //     });
  //     (window as any).gtag('config', 'G-8YG8HLBNMX', {
  //       page_title: params.slug,
  //       page_location: window.location.href,
  //       page_path: window.location.pathname,
  //     });
  //   };
  //   trackPages();
  // }, [params.slug]);

  return (
    <>
      {pageData && (
        <>
        <Breadcrumb slug={pageData.titulo} />
        <Title title={pageData.titulo} subtitle={pageData?.descricao}/>
      </>
      )}
      <Box className={classes.main}>
        
        <Box
          className={classes.box}
        >
         
          {loading && !pageData && (
          <Box
            marginBottom="60px"
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
          )}
          <Box
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: pageData?.conteudo }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Pages;
