/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import colorContext from '../../../../../../Context/colorContext';

interface Props {
  title: string;
  subtitle: string;
}

export default function Title({
  title,
  subtitle
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    title: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subtitle: {
      color: colors.textAccentColor,
      marginTop: '10px',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: 3,

    },
    btnBack: {
      display: 'flex',
      alignItems: 'center',
      color: colors.textAccentColor,
      textDecoration: 'none',
      border: `1px solid ${colors.textAccentColor}`,
      borderRadius: 25,
      fontFamily: 'Open sans',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      width: 93,
      height: 32,
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        className={classes.content}
      >

        <Box>

          <Typography
            className={classes.title}
            variant="h4"
          >
            {title}
          </Typography>
          {subtitle && <Typography
            className={classes.subtitle}
            variant="h6"
          >
            {subtitle}
          </Typography>}
        </Box>
        <Box>
          <Link to="/" onClick={() => history.goBack()} className={classes.btnBack}>
            <ArrowBack className={classes.icon} />
            Voltar
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
