import { createContext, useRef } from 'react';

const refContext = createContext({
  refCategory: null,
  refService: null,
  refEstatistic: null,
  refAttendance: null,
  refNoticias: null,
  refInicio: null,
});

export default refContext;
