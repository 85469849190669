import {
  Box,
  Card,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SearchII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listSearch: SearchII;
}

export default function ServiceList({ listSearch }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 40,
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    servicesList: {
      maxWidth: 1040,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
      gap: 16,
      [theme.breakpoints.down(755)]: {
        justifyContent: 'center',
      },
    },
    card: {
      width: '100%',
      minHeight: 154,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 4,
      padding: '24px 0px 24px 40px',
      backgroundColor: colors.colorBackground,
      borderLeft: `2px solid ${colors.accentColor}`,
      boxShadow: colors.boxShadowColor,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        textAlign: 'center',
        borderBottom: `2px solid ${colors.accentColor}`,
        borderLeft: 'none',
      },
      cursor: 'pointer',
    },
    serviceIcon: {
      fontSize: 32,
      color: colors.accentColor,
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: colors.subTitleColor,
      marginBottom: 16,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
      },
    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      fontFamily: 'Open sans',
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.serviceCardBackgroundColor,
      '&::placeholder': {
        color: '#888888',
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(14),
      },
    },
    link: {
      textDecoration: 'none',
    },
    boxInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    textTitle: {
      fontWeight: 400,
      color: colors.subTitleColor,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
    },
    textSubTitle: {
      marginTop: 16,
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    searchUpdateText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: colors.subTitleColor,
      marginTop: 22,
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      {listSearch.results.map((search) => (
        <Link to={`/${search.categoria_slug}/${search.slug}`} key={search.id} className={classes.link}>
          <Card className={classes.card}>
            <Box className={classes.boxInfo}>
              <Typography className={classes.textTitle}>{search.orgao_nome}</Typography>
              <Typography className={classes.textSubTitle}>{search.titulo}</Typography>
              <Typography className={classes.searchUpdateText}>
                Últimas modificações:
                {search.updated_at
                  ? format(new Date(search.updated_at),
                    "dd 'de' MMMM 'de' yyyy",
                    { locale: ptBR }) : 'Nunca'}
              </Typography>
            </Box>
          </Card>
        </Link>
      ))}
    </Box>
  );
}
