/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { AccountBalanceRounded } from '@material-ui/icons';
import React, { useContext } from 'react';
import { OrgansII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  organs_list: OrgansII;
}

export default function Title({ organs_list }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    title: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      lineHeight: '38.4px',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(24),
        marginTop: 10,
        marginBottom: 5,
      },
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    iconOrgans: {
      width: 80,
      height: 80,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        width: 31,
        height: 34,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography
            className={classes.title}
            variant="h4"
          >
            Serviços por órgão
          </Typography>
          <Typography
            className={classes.subTitle}
          >
            Clique no órgão desejado para visualizar a listagem dos serviços disponíveis.
          </Typography>
        </Box>
        <AccountBalanceRounded className={classes.iconOrgans} />
      </Box>
    </Box>
  );
}
