import React from 'react';
import {
  Fab, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Assignment } from '@material-ui/icons';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 0,
  },
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: '1px solid #E6E8ED',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#737B7D',
    border: '1px solid #E6E8ED',
    fontSize: theme.typography.pxToRem(12),
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function FabSearch(): JSX.Element {

  return (
    <BootstrapTooltip
      open
      title={<Typography style={{ fontWeight: 600 }}>Responder questionário</Typography>}
      placement="left"
    >
      <Fab
        style={{
          position: 'fixed',
          bottom: 80,
          right: 16,
        }}
        color="secondary"
      >
        <Assignment style={{ color: '#fff' }} />
      </Fab>
    </BootstrapTooltip>
  );
}
