import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import {
  MdChevronLeft, MdChevronRight, MdNavigateBefore, MdNavigateNext,
} from 'react-icons/md';

import { AppsI } from '../../../Home';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listApps: AppsI;
}

export default function AppsList({
  listApps,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.dividerColor}`,
    },
    card: {
      position: 'relative',
      width: 248,
      height: 248,
      borderRadius: 4,
      
      textTransform: 'capitalize',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.colorBackground,
      margin: 20,
      '&:hover': {
        boxShadow: '0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
        backgroundColor: colors.colorBackground,
      },
    },
    textTitle: {
      marginBottom: 16,
      fontWeight: 800,
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(32),
      lineHeight: '39.4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    appName: {
      color: colors.colorBackground,
      textTransform: 'none',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      lineHeight: '27.24px',
    },
    appButton: {
      border: `1px solid ${colors.colorBackground}`,
      width: '100%',
      padding: '10px 52px',
      color: colors.colorBackground,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
      textTransform: 'uppercase',
      borderRadius: 5,
      backgroundColor: colors.accentColor,

      '&:first-child': {
        marginBottom: 10,
      },

      '&:hover': {
        backgroundColor: colors.colorBackground,
        transition: 'all 0.2s ease',
        color: colors.accentColor,
      },
    },
    buttonNavigate: {
      color: colors.accentColor,
      width: 40,
      height: 40,
      border: `1px solid ${colors.accentColor}`,
      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      '&:hover': {
        backgroundColor: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
    },
    buttonLabel: {
      width: 'auto',
    },
    iconNavigate: {
      width: 24,
      height: 24,
      fontSize: 24,
    },
    slider: {
      '& .slick-list': {
        margin: 0,
        height: 248,
        '& .slick-track': {
          height: 248,
        },
      },
      '& .slick-slide': {
        padding: 0,
        marginRight: 16,
        width: '248px !important',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: '48px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const [appSelected, setAppSelected] = useState < number | null >();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1040px"
        width="100%"
        flexDirection="column"
        padding="64px 0px"
      >
       
        <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
            {listApps.results.map((app) => (
              <Card
                onMouseEnter={(): void => setAppSelected(app.id)}
                onMouseLeave={(): void => setAppSelected(null)}
                key={app.id}
                className={classes.card}
              >
                <Box
                  position="absolute"
                  height="100%"
                  width="100%"
                  display={appSelected === app.id ? 'flex' : 'none'}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-around"
                  zIndex="1"
                  style={{
                    background: colors.cardAccentColor,
                  }}
                >
                  <Typography
                    style={{
                      color: colors.textAccentColor,
                      textTransform: 'none',
                      fontSize: 14,
                    }}
                    align="center"
                  >
                    {app.nome}
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                      onClick={(): void => (window as any).open(app.url_apple)}
                      className={classes.appButton}
                      style={{ display: app.url_apple ? 'block' : 'none' }}
                      variant="outlined"
                    >
                      App Store
                    </Button>
                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                      onClick={(): void => (window as any).open(app.url_google)}
                      className={classes.appButton}
                      style={{ display: app.url_google ? 'block' : 'none' }}
                      variant="outlined"
                    >
                      Google Play
                    </Button>
                  </Box>
                </Box>
                <CardActionArea
                  style={{
                    height: '100%',
                  }}
                >
                  <CardMedia
                    style={{
                      height: '100%',
                    }}
                    image={app.url_logo}
                  />
                </CardActionArea>
              </Card>
            ))}
          </Box>
      </Box>
    </Box>
  );
}
