import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Collapse, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { getServiceTypeUser } from '../../services/servico';
import { getAllMenus, listAddress, listLogos } from '../../services/noticias';
import { LogoData } from '../Header';

interface SubMenu {
  id: string;
  slug: string;
  titulo: string;
  link: string | null;
  icone: string | null;
  link_externo: boolean | null;
  abrir_na_pagina: boolean | null;
  ordem: number;
  pagina_slug: string | null;
  pagina_publicado: boolean | null;
}

interface Result {
  id: string;
  slug: string;
  titulo: string;
  link: string;
  icone: string | null;
  local_exibicao: string;
  link_externo: boolean;
  ordem: number;
  submenu_menu: SubMenu[];
}

interface Address {
  local: string;
  rua: string;
  bairro: string;
  numero: string;
  cep: string;
  cidade: string;
  telefone: string | null;
  link_localizacao: string;
}

export default function Footer(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [services, setServices] = useState([]);
  const [footerMenus, setFooterMenus] = useState<Result[]>();
  const [footerAddress, setFooterAddress] = useState<Address>();
  const [logo, setLogo] = useState<LogoData[]>();
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff',
    },
    mainAddress: {
      width: '100%',
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff',
    },
    footerServices: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      background: '#E6E7E8',
    },
    title: {
      color: '#606062',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
      fontFamily: 'Open Sans',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    accordion: {
      background: 'transparent',
      width: '100%',
    },
    address: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: '24px 16px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
    titleServices: {
      color: '#606062',
      fontSize: theme.typography.pxToRem(18),
      minHeight: '25px',
      fontWeight: 700,
      marginBottom: 14,
      fontFamily: 'Open Sans',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 500,
      },
    },
    option: {
      fontFamily: 'Open Sans',
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
      marginBottom: 3,
      textDecoration: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    optionService: {
      fontFamily: 'Open Sans',
      color: '#606062',
      width: '100%',
      display: 'flex',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      marginBottom: 3,
      textDecoration: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    addressContent: {

    },
    optionServiceAddress: {
      fontFamily: 'Open Sans',
      color: '#606062',
      width: '100%',
      display: 'flex',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      marginBottom: 3,
      textDecoration: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        '& .optionService': {
          textAlign: 'center !important',
          justifyContent: 'center !important',
          display: 'flex',
        },
        '& .title': {
          textAlign: 'center !important',
          justifyContent: 'center !important',
          display: 'flex',
        },
      },
    },
    buttonSocial: {
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      height: 56,
      width: 56,
      minWidth: 'auto',
      borderRadius: 40,
      color: colors.iconSocialColor,
      '&:hover': {
        background: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
    },
    button: {
      justifyContent: 'flex-start',
    },
    icon: {
      width: 32,
      height: 32,
    },
    boxInfoContent: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    boxInfoContentServices: {
      margin: '40px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    boxInfo: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      minWidth: '200px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px',
        flexDirection: 'column',
        fontSize: '15px',
        width: '100%',
        color: '#606062',
      },
    },
    boxInfoService: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
    },
    footerLogo: {
      width: '341.71px',
      [theme.breakpoints.down('sm')]: {
        width: '206px',
      },
    },
    footerLogo_map: {
      width: '22px',
    },
    boxFooter: {
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const getSevices = async () => {
    try {
      const { data } = await getServiceTypeUser('Cidadão');
      const { data: dataMenus } = await getAllMenus();
      const { data: dataAddress } = await listAddress();
      const { data: Logos } = await listLogos();

      setFooterMenus(dataMenus);
      setLogo(Logos);
      setFooterAddress(dataAddress[0]);
      setServices(data.slice(0, 5));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSevices();
  }, []);

  const footerMenusWithChildren = footerMenus && footerMenus?.filter((item) => item.submenu_menu && item.submenu_menu.length > 0);
  const footerMenusWithoutChildren = footerMenus && footerMenus?.filter((item) => (!item.submenu_menu || item.submenu_menu.length === 0) && item.local_exibicao === 'Rodapé');

  function chunkArray(myArray, chunk_size) {
    let index = 0;
    const arrayLength = myArray?.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      tempArray.push(myArray.slice(index, index + Number(chunk_size)));
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return tempArray;
  }

  const theme = useTheme();
  const footerMenusWithoutChildrenChunks = chunkArray(footerMenusWithoutChildren, 5);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleToggleSubMenu = (id) => {
    setOpenSubMenu(openSubMenu === id ? null : id);
  };

  return (
    <>
      <Box className={classes.footerServices}>
        <Box display="flex" maxWidth="1040px" width="100%" flexDirection="column" justifyContent="center">
          <Box
            className={classes.boxInfoContentServices}
          >
            {footerMenusWithChildren && footerMenusWithChildren.sort((a, b) => a.ordem - b.ordem).map((item) => (
              item.local_exibicao === 'Rodapé' && !item.titulo.toLowerCase().includes('setdig') && (
                <Box key={item.id} style={{ width: '100%', padding: '0px 16px' }}>
                  <Typography
                    className={classes.titleServices}
                    component="div"
                    onClick={() => handleToggleSubMenu(item.id)}
                    style={{ display: 'flex', justifyContent: isMobile && 'space-between', borderBottom: isMobile && '1px solid #6060624D' }}
                  >
                    {item.titulo}
                    {isMobile && (openSubMenu === item.id ? <ExpandLess /> : <ExpandMore />)}
                  </Typography>
                  <Collapse in={isMobile ? openSubMenu === item.id : true}>
                    {item?.submenu_menu?.sort((a, b) => a.ordem - b.ordem).map((submenu) => (
                      <a
                        key={submenu.id}
                        href={submenu.pagina_slug !== null ? `/pagina/${submenu.pagina_slug}` : submenu.link}
                        target={!submenu.abrir_na_pagina ? '_blank' : '_self'}
                        rel={!submenu.abrir_na_pagina ? 'noopener noreferrer' : ''}
                        className={classes.optionService}
                      >
                        {submenu.titulo}
                      </a>
                    ))}
                  </Collapse>
                </Box>
              )
            ))}
            {footerMenusWithoutChildrenChunks
            && footerMenusWithoutChildrenChunks?.map((group) => (
              <Box key={group.id} className={classes.boxInfo}>
                {!isMobile && (
                <Typography
                  className={classes.titleServices}
                  component="div"
                  style={{ display: 'flex', justifyContent: isMobile && 'space-between', borderBottom: isMobile && '1px solid #6060624D' }}
                />
                )}
                {group.map((item) => (
                  <a
                    key={item.id}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={!isMobile ? classes.optionService : classes.titleServices}
                  >
                    {item.titulo}
                  </a>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.mainAddress}>
        <Box display="flex" maxWidth="1040px" width="100%" flexDirection="row" minHeight="142px" justifyContent="space-between" flexWrap="wrap" className={classes.address}>
          <Box className={classes.addressContent}>
            <Typography
              className={classes.title}
              style={{ cursor: 'auto' }}
            >
              Governadoria do Estado de Mato Grosso do Sul
            </Typography>
            <Typography
              className={classes.optionServiceAddress}
              style={{ cursor: 'auto' }}
            >
              {footerAddress?.rua}
            </Typography>
            <Typography
              className={classes.optionServiceAddress}
              style={{ cursor: 'auto' }}
            >
              {footerAddress?.bairro}
            </Typography>
            <Typography
              className={classes.optionServiceAddress}
              style={{ cursor: 'auto' }}
            >
              {footerAddress?.cidade}
            </Typography>
            <Typography
              className={classes.optionServiceAddress}
              style={{ cursor: 'auto' }}
            >
              CEP:
              {' '}
              {footerAddress?.cep}
            </Typography>
            <a href={footerAddress?.link_localizacao} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: '700', width: 'fit-content' }} className={classes.optionServiceAddress}>
              <Typography
                className={classes.titleServices}
                style={{
                  display: 'flex', alignItems: 'flex-start', gap: '10px', color: '#004F9F', cursor: 'pointer',
                }}
              >
                MAPA
                <img
                  alt="Mapa"
                  src="/assets/images/map_icon.svg"
                  className={classes.footerLogo_map}
                />
              </Typography>
            </a>
          </Box>
          <Box>
            <img
              alt={logo && logo[1].titulo}
              src={logo && logo[1].url_imagem}
              className={classes.footerLogo}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
