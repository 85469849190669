import React, { useContext } from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import colorContext from '../../../../Context/colorContext';
import { VideoProps } from '../../../Home';

interface Props {
  getOrgans: (page: number) => void;
  videosList: VideoProps;
}

export default function PaginationService({
  getOrgans,
  videosList,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      marginBottom: 20,
      borderRadius: '50%',
      color: '#fff',
      '& .Mui-selected': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackgroundSecundary,
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: colors.accentColor,
          color: colors.colorBackgroundSecundary,
        },
      },
    },
    paginationItem: {
      color: colors.textBody,
      '&:hover': {
        color: colors.colorBackground,
        backgroundColor: `${colors.accentColor} !important`,
      },
    },
    paginationSelected: {
      color: colors.colorBackground,
      backgroundColor: `${colors.accentColor} !important`,
      '&:hover': {
        color: colors.colorBackground,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ root: classes.paginationItem, selected: classes.paginationSelected }}
          />
        )}
        count={videosList.total_pages}
        page={videosList.current}
        onChange={(e, page) => getOrgans(page)}
      />
    </Box>
  );
}
