/* eslint-disable linebreak-style */
import { Box, IconButton, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { MenuListLinks, Submenu } from '..';
import colorContext from '../../../../../Context/colorContext';
import { listAllOrgan } from '../../../../../services/orgao';

type Props = {
  open: boolean;
  title: string;
  categories: MenuListLinks[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface OrgansListProps {
  id: number;
  nome: string;
  sigla: string;
  slug: string;
}

export default function HoverMenu({
  open, setOpen, title, categories,
}: Props): JSX.Element {
  const [organs, setOrgans] = useState<OrgansListProps[]>();
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minHeight: 400,
      position: 'absolute',
      zIndex: 9999,
      backgroundColor: colors.colorBackgroundMainMenu,
    },
    container: {
      width: '100%',
      maxWidth: 1040,
      padding: '24px 16px',
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        flexDirection: 'row',
      },
    },
    boxCategory: {
      width: '70%',
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

    },
    boxUnity: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    linkCategory: {
      color: colors.textAccentColor,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 10,
      marginRight: 10,
      padding: '5px 5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    linkCategoryService: {
      color: colors.textAccentColor,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 10,
      marginRight: 10,
      padding: '5px 5px',
      whiteSpace: 'nowrap',
    },
    boxLinksCategory: {
      columns: '2 auto',
      [theme.breakpoints.down('xs')]: {
        columns: '1',
      },
    },
    boxLinksCategoryMenu: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      gap: '10px',
    },
    link: {
      color: colors.textAccentColor,
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 24,
    },
    boxBtnClose: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  }));

  const classes = useStyles();

  const getAllOrgans = async () => {
    try {
      const response = await listAllOrgan();
      setOrgans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrgans();
  }, []);

  const handleonMouseLeave = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!open) {
    return null;
  }

  const renderSubmenuItems = (menuItems: Submenu[]) => menuItems.map((submenu) => (
    <a
      key={submenu.id}
      href={submenu.pagina_slug ? `/pagina/${submenu.pagina_slug}` : submenu.link}
      onClick={handleClose}
      className={classes.linkCategory}
      target={!submenu.abrir_na_pagina ? '_blank' : '_self'}
      rel={!submenu.abrir_na_pagina ? 'noopener noreferrer' : ''}
    >
      {submenu.titulo}
    </a>
  ));

  const renderAlphabeticalOrdering = (a: OrgansListProps, b: OrgansListProps) => (a.sigla.toLocaleLowerCase() > b.sigla.toLocaleLowerCase() ? 1 : -1);

  return (
    <nav className={classes.main} onMouseLeave={handleonMouseLeave}>
      <Box className={classes.container}>
        <Box display="flex">
          <Box className={classes.boxCategory}>
            <Typography className={classes.link}>{title === 'Serviços' ? 'Serviços por Categoria' : title}</Typography>
            {categories && categories.filter((menu) => menu.titulo === title).map((menu) => (
              <Box className={classes.boxLinksCategory}>
                {renderSubmenuItems(menu.submenu_menu)}
              </Box>
            ))}
          </Box>
          <Box style={{ minWidth: '300px' }}>
            {categories && categories.filter((menu) => menu.titulo === title).map((menu) => (
              <>
                {menu.menu?.titulo && <Typography className={classes.link}>{menu.menu.titulo}</Typography>}
                <Box className={classes.boxLinksCategoryMenu}>
                  {menu.menu?.submenu_menu && renderSubmenuItems(menu.menu.submenu_menu)}
                </Box>
              </>
            ))}
          </Box>
          {title === 'Serviços'
            && (
            <Box className="flex flex-col">
                {organs && <a className={classes.link} href="/orgaos/servicos-por-orgao" target="_blank">Serviços por Órgão</a>}
              <Box className={classes.boxLinksCategory}>
                {organs && organs.sort(renderAlphabeticalOrdering).map((menu) => (
                  <>
                    <a
                      key={menu.id}
                      href={menu.slug && `/orgao/${menu.slug}/${menu.id}`}
                      onClick={handleClose}
                      className={classes.linkCategoryService}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {menu.sigla}
                    </a>
                  </>
                ))}
              </Box>
            </Box>
            )}
        </Box>
        <Box className={classes.boxBtnClose}>
          <IconButton onClick={handleClose}>
            <Close style={{ color: colors.textAccentColor }} />
          </IconButton>
        </Box>
      </Box>
    </nav>
  );
}
