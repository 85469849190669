/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Link, RouteComponentProps, matchPath, useHistory,
} from 'react-router-dom';
import {
  MdBusiness, MdFace, MdSearchOff, MdWork,
} from 'react-icons/md';
import { ServiceProfileI } from '../..';
import colorContext from '../../../../Context/colorContext';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  listServiceProfile: ServiceProfileI;
}

interface MatchParams {
  category: string;
  slug: string;
}

export const match = (pathname: string): string => {
  const matchParams = matchPath<MatchParams>(pathname, {
    path: '/:category/:slug',
    exact: true,
  });

  if (matchParams && matchParams.params) {
    return `/${matchParams.params.category}/${matchParams.params.slug}`;
  }

  return '/';
};

export default function ServiceProfile({
  listServiceProfile,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '0px 16px',
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.textSecondary,
      fontWeight: 700,
      backgroundColor: colors.colorBackground,
      textTransform: 'uppercase',
      padding: '15px 40px',
      textAlign: 'center',
      fontSize: 15,
      width: '100%',
      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '4px 0px 4px 0px',
      },
    },
    textTitleSelected: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColor,
    },
    card: {
      width: 512,
      height: 94,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 2,
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.16)',
      textDecoration: 'none',
      color: colors.textAccentColor,
      background: colors.colorBackground,
      position: 'relative',
      border: '1px solid #ffffff',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EDF5FB',
        transition: 'all 0.2s ease',
        border: '1px solid #EDF5FB',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
      },
    },
    carIconBox: {
      display: 'flex',
      width: 82,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },

    boxServicesSelected: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    serviceIcon: {
      fontSize: 38,
      color: colors.accentColor,
    },
    tabHovered: {
      backgroundColor: '#EDF5FB',
      transition: 'background-color 0.3s ease',
    },
    servicesList: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: '100%',
      gap: 16,
      [theme.breakpoints.down(1040)]: {
        justifyContent: 'center',
      },
    },
    servicesSelected: {
      display: 'flex',
      alignItems: 'center',
      margin: '48px 0px 40px 0px',
      border: '1px solid #e3e3e3',
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '40px 0px',
        flexWrap: 'wrap',
      },
    },
    boxTextCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'initial',
      width: 430,
      height: '100%',
      padding: '16px 16px 16px 0',
    },
    textCard: {
      color: colors.textGrayStrong,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      fontFamily: 'Open Sans',

    },
    textCategoryName: {
      color: colors.textSecondary,
      fontSize: theme.typography.pxToRem(10),
      textTransform: 'uppercase',
      fontWeight: 400,
      fontFamily: 'Open Sans',
      marginBottom: 5,

    },
    textTitleInitial: {
      fontSize: theme.typography.pxToRem(36),
      textTransform: 'uppercase',
      textAlign: 'left',
      marginBottom: 10,
      fontWeight: 800,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitleInitial: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    buttonMore: {
      alignSelf: 'center',
      marginTop: 40,
      border: 'solid 1px',
      borderRadius: 4,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(15),
      padding: '8px 39px',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
      },
    },
    boxWanted: {
      marginTop: 30,
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
    },
    wanted: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      fontWeight: 600,
    },
    wantedIcon: {
      width: 40,
      height: 40,
      color: colors.subTitleColor,
    },
    serviceCard: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 5,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:755px)');
  const [showMore, setShowMore] = useState<boolean>(true);
  const [hoveredTab, setHoveredTab] = useState('');

  const handleTabHover = (tabName) => {
    setHoveredTab(tabName);
  };

  const handleTabLeave = () => {
    setHoveredTab('');
  };
  const [serviceSelected, setServiceSelected] = useState<
  'cidadao' | 'servidor' | 'empresa' | 'gestao_publica'
  >('cidadao');

  const servicesLists = showMore ? listServiceProfile[serviceSelected] : listServiceProfile[serviceSelected]?.slice(0, 6);

  function stringToSlug(str: string): string {
    return str
      ? str
        .toLowerCase()
        .replace(/\s+/g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9\-]/g, '')
      : '';
  }

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1040px"
        width="100%"
        flexDirection="column"
        padding="64px 0px 10px 0px"
      >
        <Typography className={classes.textTitleInitial} variant="h1">
          Serviços em destaque
        </Typography>
        <Typography className={classes.textSubtitleInitial}>
          Serviços recomendados por público alvo.
        </Typography>
        <Box className={classes.boxServicesSelected}>
          <Box className={classes.servicesSelected}>
            <Typography
              onClick={() => setServiceSelected('cidadao')}
              onMouseEnter={() => handleTabHover('cidadao')}
              onMouseLeave={handleTabLeave}
              className={
              serviceSelected === 'cidadao'
                ? `${classes.textTitle} ${classes.textTitleSelected}`
                : `${classes.textTitle} ${hoveredTab === 'cidadao' ? classes.tabHovered : ''}`
              }
              variant={matches ? 'h2' : undefined}
            >
              {matches ? (
                <>Cidadão</>
              ) : (
                <>Cidadão</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('servidor')}
              onMouseEnter={() => handleTabHover('servidor')}
              onMouseLeave={handleTabLeave}
              className={
              serviceSelected === 'servidor'
                ? `${classes.textTitle} ${classes.textTitleSelected}`
                : `${classes.textTitle} ${hoveredTab === 'servidor' ? classes.tabHovered : ''}`
              }
              variant={matches ? 'h2' : undefined}
            >
              {matches ? (
                <>Servidor Público</>
              ) : (
                <>Servidor Público</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('empresa')}
              onMouseEnter={() => handleTabHover('empresa')}
              onMouseLeave={handleTabLeave}
              className={
              serviceSelected === 'empresa'
                ? `${classes.textTitle} ${classes.textTitleSelected}`
                : `${classes.textTitle} ${hoveredTab === 'empresa' ? classes.tabHovered : ''}`
              }
              variant={matches ? 'h2' : undefined}
            >
              {matches ? (
                <>Empresa</>
              ) : (
                <>Empresa</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('gestao_publica')}
              onMouseEnter={() => handleTabHover('gestao_publica')}
              onMouseLeave={handleTabLeave}
              className={
              serviceSelected === 'gestao_publica'
                ? `${classes.textTitle} ${classes.textTitleSelected}`
                : `${classes.textTitle} ${hoveredTab === 'gestao_publica' ? classes.tabHovered : ''}`
              }
              variant={matches ? 'h2' : undefined}
            >
              {matches ? (
                <>Gestão Pública</>
              ) : (
                <>Gestão Pública</>
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.servicesList}>
          {servicesLists?.length > 0 && servicesLists?.map((service) => (
            <Link
              key={service.id}
              className={classes.card}
              to={`/${stringToSlug(service.categoria_nome)}/${service.slug}`}
            >
              <Box className={classes.carIconBox}>
                <span
                  className={`material-icons ${classes.serviceIcon}`}
                >
                  {service.categoria_icone}
                </span>
              </Box>
              <Box className={classes.boxTextCard}>
                <Box>
                  <Typography className={classes.textCategoryName}>
                    {service.orgao_nome}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.textCard} variant="h3">
                    {service.titulo}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
          {servicesLists?.length === 0 && (
            <Box className={classes.boxWanted}>
              <MdSearchOff className={classes.wantedIcon} />
              <Typography className={classes.wanted}>Nenhum serviço em destaque</Typography>
            </Box>
          )}
        </Box>
        {/* <Button
            className={classes.buttonMore}
            onClick={(): void => history.push('/servicos')}
          >
            VER TODOS
          </Button> */}
      </Box>
    </Box>
  );
}
