import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import './style.css';
import colorContext from '../../../../Context/colorContext';

interface Props {
  content: string;
}

export default function ContentNew({
  content,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: '1040px',
      backgroundColor: colors.colorBackground,
      marginBottom: 40,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
      '& img, figure': {
        maxWidth: '60vw !important',
        height: 'auto',

      },
      '& p': {
        maxWidth: '100% !important',
      },
      '& figcaption': {
        maxWidth: '500px !important',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '250px !important',
          display: 'flex',
        },
      },

    },
    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    content: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      fontWeight: 400,
      textAlign: 'justify',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Typography
        dangerouslySetInnerHTML={{ __html: content }}
        className={classes.content}
      />
    </Box>
  );
}
