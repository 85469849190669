/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, {
  useContext, useEffect, useState,
} from 'react';
import colorContext from '../../Context/colorContext';
import refContext from '../../Context/refContext';
import { getAllServicesList } from '../../services/servico';
import { SearchII } from '../SearchService';
import {
  Breadcrumb,
  Services,
  Title,
} from './components';

export default function ServiceList(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [serviceList, setServiceList] = useState<SearchII>();
  const [loading, setLoading] = useState(true);

  const { refInicio } = useContext(refContext);

  const getServices = async () => {
    setLoading(true);
    const { data } = await getAllServicesList();
    setServiceList(data);
    setLoading(false);
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    refInicio.current.scrollIntoView();
  }, [loading]);

  return (
    <div>
      <Breadcrumb slug="Todos os serviços" />
      <Title />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="600px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {serviceList?.results.length > 0 ? (
            <>

              <Services services={serviceList} />
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="600px"
            >
              <Typography
                style={{ fontSize: 25, color: colors.textBody }}
              >
                Nenhum serviço encontrado
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
}
