/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { BsFillGridFill } from 'react-icons/bs';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdExitToApp, MdExpandMore } from 'react-icons/md';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
  Link as LinkRouter,
  useHistory, useLocation,
} from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

import {
  clearStorage,
  getImageProfile,
  getUserData as getUserDataRequest,
  loginRedirectPrivider,
  logout,
} from '../../../../services/auth';

import { featuredImage, getAllMenus } from '../../../../services/noticias';
import { UserData } from '../../../../services/user';
import { hideMaskCPF } from '../../../../utils/cpfMask';
import getCookie from '../../../../utils/getCookies';
import { getInitialsFromName, nameSplitOne } from '../../../../utils/nameFormat';
import { setCookie } from '../../../../utils/setCookie';
import HoverMenu from './hoverMenu';
import HoverMenuMobile from './hoverMenuMobile';

export interface Submenu {
  id: string;
  slug: string;
  titulo: string;
  link: string;
  icone: string | null;
  link_externo: boolean;
  pagina_slug: string | null;
  pagina_publicado: string | null;
  abrir_na_pagina: boolean;
}

export interface MenuListLinks {
  id: string;
  ordem: number;
  slug: string;
  titulo: string;
  link: string | null;
  icone: string | null;
  local_exibicao: string;
  menu: MenuList,
  submenu_menu: Submenu[];
}

export interface MenuList {
  id: string;
  ordem: number;
  slug: string;
  titulo: string;
  link: string | null;
  icone: string | null;
  local_exibicao: string;
  submenu_menu: Submenu[];
}

type PatternImage = {
  titulo: string;
  url_imagem: string;
  url: string | null;
};

export default function Search(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    },
    boxSearch: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '15px 10px 20px 0px',
      maxWidth: '1040px',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    formSearch: {
      width: '1040px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E6E6E6',
      [theme.breakpoints.down('md')]: {
        width: 'calc(1040px - 46px)',
        display: '100% !important',
        padding: '6px 4px',
      },
    },
    boxInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '10px',
      backgroundColor: colors.colorBackground,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    iconMenu: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: theme.typography.pxToRem(10),
      },
    },
    loginButton: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'normal',
      fontFamily: 'Open Sans',
      color: colors.colorBackground,
      backgroundColor: '#0057AF',
      borderRadius: 4,
      lineHeight: '18px',
      padding: '10px 18px 10px 16px',
      '&:hover': {
        color: colors.textAccentColor,
        backgroundColor: colors.colorBackgroundSecundary,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
        padding: '4px 10px',
      },
    },
    iconButton: {
      borderRadius: '10px',
      minWidth: 190,
      textAlign: 'center',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(13),
      textTransform: 'capitalize',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
      height: 45,
      [theme.breakpoints.down('sm')]: {
        minWidth: '40%',
        fontSize: theme.typography.pxToRem(11),
      },
    },
    iconSearch: {
      color: colors.textAccentColor,
      width: 24,
      height: 24,
    },
    banner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 16px 0px 16px',
      },
    },
    bannerFixo: {
      height: 'auto',
      maxWidth: '100%', // use 100% para ser totalmente responsivo
      width: '1040px', // limite a largura a 1040px se a tela for maior que isso
    },
    iconLogin: {
      fontSize: theme.typography.pxToRem(24),
      color: 'inherit',
      marginLeft: 0,
      marginRight: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    loginBox: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 10,
      fontWeight: 500,
      color: colors.textBody,
    },
    inputSearch: {
      width: '100%',
      outline: 'none',
      paddingLeft: 25,
      fontFamily: 'Open Sans',
      fontSize: 14,
      borderRadius: '10px 0px 0px 10px',
      color: '#0057AF',
      opacity: 1,
      '&::placeholder': {
        color: '#0057AF',
        opacity: 1,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
      border: 'none',
      height: 45,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: '5px 0px 0px 5px',
      },
    },
    titleSearch: {
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(18),
      width: '100%',
      textAlign: 'center',
    },
    boxSearchMenus: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: '#4D4D4D',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    boxSearchMenusItems: {
      borderRight: '1px solid #666666',
      cursor: 'pointer',
      padding: '0px 30px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        fontWeight: 700,
      },
    },
    menuItem: {
      minHeight: 42,
      color: '#737B7D',
      minWidth: 236,
      backgroundColor: colors.colorBackground,
      '&:hover': {
        background: colors.colorBackground,
      },
    },
    avatar: {
      height: 32,
      width: 32,
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      border: `1px solid ${colors.accentColor}`,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      textAlign: 'start',
      color: colors.accentColor,
    },
    textLegend: {
      color: '#FFF',
      fontFamily: 'Roboto',
      fontWeight: 500,
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(16),
      textShadow: '#000 0.1em 0.1em 0.2em',
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    avatarHome: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    lastItem: {
      borderRight: 'none',
    },
    boxFormSearch: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      paddingTop: 12,
      [theme.breakpoints.down('sm')]: {
        padding: '10px 16px 0px 16px',
      },
    },
    inputSearchIcon: {
      color: 'theme.palette.accentColor',
    },
    placeholder: {
      color: 'blue', // Cor do placeholder (azul neste exemplo)
    },
    searchIcon: {
      position: 'absolute',
      left: '10px',
      fontSize: '21px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'blue',
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:1012px)');
  const matchesMobile = useMediaQuery('(min-width:1200px)');
  const token: string | null = getCookie('gov_access_token_sso');
  const tokenSiseci: string | null = getCookie(
    'gov_access_token_siseci',
  );
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [avatarUser, setAvatarUser] = useState<string>('');
  const [textSearch, setTextSearch] = useState<string>('');
  const [patternImage, setPatternImage] = useState<PatternImage>(null);
  const [userData, setUserData] = useState<UserData | null>();
  const [menu, setMenus] = useState<MenuListLinks[] | null>();
  const [message, setMessage] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      history.push(`/buscar/q=${encodeURIComponent(textSearch)}`);
      setTextSearch('');
    }
  };

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
      }
    };

    handleGetImageProfile();
  }, [token]);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElUser(null);
  };

  const fetchMenus = async () => {
    try {
      const [linksMenuResult, imageResult] = await Promise.allSettled([getAllMenus(), featuredImage()]);

      if (linksMenuResult.status === 'fulfilled' && linksMenuResult.value.data) {
        setMenus(linksMenuResult.value.data);
      }

      if (imageResult.status === 'fulfilled' && imageResult.value.data) {
        setPatternImage(imageResult.value.data[0]);
      }
    } catch (error) {
      console.error('Error fetching menu or image:', error);
    }
  };

  useEffect(() => {
    const { state } = location;
    if (state as any) {
      setMessage((state as any).message);
    }
  }, [location]);

  const logoutUser = () => {
    logout();
    setUserData(undefined);
    setAnchorElUser(null);
  };

  const getUserData = async (tokenSso: string, tokenSiseciReceive?: string) => {
    try {
      setLoading(true);

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);

      setUserData(data);
      setCookie('gov_user_data', JSON.stringify(data));
      // setLoading(false);
    } catch (err) {
      clearStorage();
      if (err.response?.data) {
        setMessage(err.response.data.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
    fetchMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
  }, [token]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'user' | 'access',
  ) => {
    if (type === 'user') {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [anchorElPop, setAnchorElPop] = React.useState(null);
  const [nameMenu, setNameMenu] = React.useState('');

  const handlePopoverOpen = (event) => {
    setAnchorElPop(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElPop(null);
  };

  const goToPage = (link: string) => {
    (window as any).open(link);
  };

  const ListMenuLink = menu && menu.filter((menuItem) => menuItem.local_exibicao === 'Cabeçalho');

  return (
    <section style={{ backgroundColor: '#fff' }}>
      <Box style={{ width: '100%', padding: '5px 0 0 0' }} onMouseEnter={() => handlePopoverClose()} />
      <div className={classes.main}>
        <Box className={classes.boxSearch}>
          <Box className={classes.avatarHome}>
            <img
              alt="not found"
              src="/assets/images/icon-home.svg"
            />
          </Box>
          <Box className={classes.boxSearchMenus}>
            {ListMenuLink
              && ListMenuLink.map((categoria, index) => (
                <Typography
                  className={`${classes.boxSearchMenusItems} ${index === ListMenuLink.length - 1 && classes.lastItem}`}
                  key={categoria.titulo}
                  onClick={
                   () => {
                     if (categoria.titulo === 'Órgãos e Secretarias') {
                       goToPage('/orgaos');
                     }
                   }
                }
                  onMouseEnter={(e) => {
                    if (categoria.titulo !== 'Órgãos e Secretarias') {
                      handlePopoverOpen(e);
                      setNameMenu(categoria.titulo);
                    } else {
                      handlePopoverClose();
                    }
                  }}
                >
                  {categoria.titulo}
                </Typography>
              ))}
          </Box>
          <Popover
            open={Boolean(anchorElUser)}
            anchorEl={anchorElUser}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box className={classes.loginBox}>
              {userData && (
                <MenuItem
                  style={{
                    backgroundColor: colors.colorBackground,
                    // minHeight: 73,
                  }}
                >
                  <Avatar
                    src={avatarUser || null}
                    alt={getInitialsFromName(userData.nome || '')}
                    className={classes.avatar}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography className={classes.textName}>
                      {nameSplitOne(userData?.nome?.toLowerCase())}
                    </Typography>
                    <Typography className={classes.textCpf}>
                      {hideMaskCPF(userData?.cpf)}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              <MenuItem className={classes.menuItem}>
                <LinkRouter
                  to="/workspace"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    textDecoration: 'none',
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  <BsFillGridFill style={{ color: colors.accentColor }} />
                  <Typography className={classes.textMenuItem}>
                    Meu Painel
                  </Typography>
                </LinkRouter>
              </MenuItem>
              {/* <Divider  /> */}
              <MenuItem onClick={logoutUser} className={classes.menuItem}>
                <MdExitToApp style={{ color: colors.buttonErrorColor }} />
                <Typography className={classes.textMenuItem}>Sair</Typography>
              </MenuItem>
              {/* <Divider /> */}
            </Box>
          </Popover>

          <Box className={classes.loginBox}>
            {loading ? (
              <CircularProgress
                style={{ width: 25, height: 25 }}
                color="primary"
              />
            ) : (
              <>
                {userData ? (
                  <Button
                    color="primary"
                    onClick={(e): void => handleClick(e, 'user')}
                  >
                    <Avatar
                      src={avatarUser || null}
                      alt={getInitialsFromName(userData.nome || '')}
                      className={classes.avatar}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.textName} color="primary">
                        {nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography color="primary" className={classes.textCpf}>
                        {hideMaskCPF(userData?.cpf)}
                      </Typography>
                    </Box>
                    <MdExpandMore style={{ color: colors.accentColor }} />
                  </Button>
                ) : (
                  <Button
                    className={classes.loginButton}
                    onClick={loginRedirect}
                    startIcon={<FaRegUserCircle />}
                    classes={{
                      startIcon: classes.iconLogin,
                    }}
                  >
                    Entrar
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </div>
      {matchesMobile && <HoverMenu open={anchorElPop} setOpen={setAnchorElPop} title={nameMenu} categories={ListMenuLink} />}
      <Box className={classes.banner}>
        <a href={patternImage?.url} target="_blank" rel="noreferrer">
          <img
            alt={patternImage && patternImage?.titulo}
            src={patternImage && patternImage?.url_imagem}
            className={classes.bannerFixo}
          />
        </a>
      </Box>
      <Box className={classes.boxFormSearch}>
        <img
          alt="Contraste"
          src="/assets/images/icon_menu_.svg"
          style={{ padding: '0px 10px' }}
          className={classes.iconMenu}
          onClick={(e) => handlePopoverOpen(e)}
        />
        <form
          onSubmit={submitSearch}
          className={classes.formSearch}
        >
          <div className={classes.boxInput}>
            <Input
              value={textSearch}
              onChange={(e): void => setTextSearch(e.target.value)}
              className={`${classes.inputSearch}`}
              placeholder="Ex: CNH, Matrícula escolar..."
              disableUnderline
              startAdornment={(
                <InputAdornment position="start">
                  <img
                    alt="Contraste"
                    src="/assets/images/searchIcon.svg"
                    className={classes.searchIcon}
                  />
                </InputAdornment>
              )}
            />
            <Button
              type="submit"
              className={classes.iconButton}
            >
              BUSCAR SERVIÇO
            </Button>
          </div>
        </form>
      </Box>
      {!matchesMobile && <HoverMenuMobile open={anchorElPop} setOpen={setAnchorElPop} title={nameMenu} categories={ListMenuLink} />}
    </section>
  );
}
