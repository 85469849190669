import { AxiosResponse } from 'axios';
import { apisso } from './api';

export const loadCurrentTermSSO = (token: string): Promise<AxiosResponse> => apisso
  .get('admin/termos/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const loadSignedTermSSO = (token: string, page = 1): Promise<AxiosResponse> => apisso
  .get('admin/termos_assinados/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
    },
  });

export const loadPendingTermSSO = (token: string): Promise<AxiosResponse> => apisso
  .get('admin/termos_pendentes/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const signTermSSO = (token: string, term_id: string): Promise<AxiosResponse> => apisso
  .post('admin/assinar_termo/', { termo: term_id }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const totalUsers = (): Promise<AxiosResponse> => apisso
  .get('admin/quantidade_usuarios/', {
    headers: {
      'Content-Type': 'application/json',
    },
  });
