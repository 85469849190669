import React, { useContext } from 'react';
import {
  Box, Typography, Button, Card, CardActionArea, CardContent,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { SearchII } from '../..';
import colorContext from '../../../../Context/colorContext';
import { match } from '../../../Home/components/ServiceProfile';

interface Props {
  listSearch: SearchII;
  orgaoSlug: string;
}

export default function ServiceList({ listSearch, orgaoSlug }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
	  flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    servicesList: {
      maxWidth: 1040,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      width: '100%',
      gap: 16,
      [theme.breakpoints.down(755)]: {
        justifyContent: 'center',
      },
    },
    card: {
      borderRadius: 4,
      width: 336,
      height: 180,
      color: colors.textAccentColor,
      background: colors.serviceCardBackgroundColor,
      borderBottom: `1px solid ${colors.accentColor}`,
      position: 'relative',
      '&:hover': {
        backgroundColor: 'rgba(0, 63, 126, 0.1)',
        transition: 'all 0.2s ease',
      },
    },
    cardContent: {
      padding: 24,
      height: '100%',
      borderBottom: `1px solid ${colors.accentColor}`,
    },
    serviceIcon: {
      fontSize: 32,
      color: colors.accentColor,
    },
    textCard: {
      color: colors.textBody,
      fontWeight: 600,
      textAlign: 'start',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    textCategoryName: {
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        fontSize: theme.typography.pxToRem(13),
      },
    },
    text: {
      fontWeight: 'normal',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    boxService: {
      margin: '8px 0px',
      width: '100%',
      minHeight: 56,
      height: 'auto',
      backgroundColor: colors.colorBackgroundSecundary,
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      padding: 18,
      display: 'flex',
      alignItems: 'center',
    },
    serviceText: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'start',
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
    },
    serviceCard: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 5,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    buttonMore: {
      alignSelf: 'center',
      marginTop: 40,
      border: 'solid 1px',
      borderRadius: 4,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(15),
      padding: '8px 39px',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
      },
    },
    cardLink: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const servicesList = listSearch.results.slice(0, 6);
  return (
    <Box className={classes.main}>
      <Box className={classes.servicesList}>
        {servicesList.map((search) => (
          <Link to={`/${search.categoria_slug}/${search.slug}`} className={classes.cardLink}>
            <Card
              key={search.id}
              className={classes.card}
            >
              <Box
                style={{
                  height: '100%',
                  borderBottom: `1px solid ${colors.accentColor}`,
                }}
              >
                <CardContent className={classes.cardContent}>
                  <Box
                    className={classes.serviceCard}
                  >
                    <Typography className={classes.textCategoryName}>
                      {search.categoria_nome}
                    </Typography>
                    <span className={`material-icons ${classes.serviceIcon}`}>
                      {search.categoria_icone}
                    </span>
                  </Box>
                  <Typography className={classes.textCard}>
                    {search.titulo}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Link>
        ))}
      </Box>

      <Button
        className={classes.buttonMore}
        onClick={(): void => history.push(`/orgao/${orgaoSlug}/servicos`)}
      >
        VER TODOS
      </Button>
    </Box>
  );
}
