import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, Link as Linker } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { News } from '../../../News';
import colorContext from '../../../../Context/colorContext';

interface Props {
  newsList: News;
}

export default function NewsComponent({ newsList }: Props): JSX.Element {
  const history = useHistory();
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.dividerColor}`,
      padding: '0px 16px',
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(36),
      textTransform: 'uppercase',
      textAlign: 'left',
      marginBottom: 10,
      fontWeight: 800,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(16),
      },
    },
    card: {
      width: '100%',
      height: 374,
      borderRadius: 0,
      position: 'relative',
    },
    newSubtitle: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '18.2px',
      textTransform: 'capitalize',
      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    newSubtitleBackground: {
      fontSize: theme.typography.pxToRem(14),
      padding: '3px 6px',
      borderRadius: '6px',
      backgroundColor: '#316BBE',
      color: colors.textAccentColor,
      fontWeight: 700,
      lineHeight: '18.2px',
      textTransform: 'capitalize',
      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    newTitle: {
      marginTop: 16,
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 700,
      color: colors.textBody,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    textMore: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      marginTop: 40,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    cardNews: {
      display: 'flex',
    },
    buttonMoreNews: {
      color: colors.accentColor,
      padding: '8px 39px',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '24px',
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,

      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
      },
    },
    link: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();

  const redirectToNew = (slug) => {
    history.push(`/noticias/${slug}`);
  };

  return (
    <Box className={classes.main}>
      <Box display="flex" maxWidth="1040px" width="100%" flexDirection="column" padding="30px 0px 64px 0px">
        <Box>
          <Typography className={classes.textTitle} variant="h1">
            Notícias
          </Typography>

          <Typography className={classes.textSubtitle}>
            Confira as últimas notícias do estado.
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 49,
          }}
        >
          {newsList.results.slice(0, 3).map((newItem) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={newItem.id}
              className={classes.cardNews}
            >
              <Linker
                key={newItem.id}
                to={`/noticias/${newItem.slug}`}
                style={{
                  backgroundColor: colors.colorBackground,
                  boxShadow: colors.boxShadowColor,
                  textDecoration: 'none',
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    alt="Noticias"
                    height="222"
                    image={newItem.imagem_destaque_url}
                    title="Noticias"
                  />
                  <CardContent style={{ padding: '24px 24px 21px 24px' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap={4}
                    >
                      <Typography className={classes.newSubtitleBackground} variant="h2">
                        {newItem.categoria_titulo}
                      </Typography>
                      <Typography className={classes.newSubtitle}>
                        -
                      </Typography>
                      <Typography className={classes.newSubtitle}>
                        { format(new Date(newItem.created_at), 'dd MMMM yyyy', {
                          locale: ptBR,
                        })}
                      </Typography>
                    </Box>
                    <Typography variant="h2" className={classes.newTitle}>
                      {newItem.titulo}
                    </Typography>
                  </CardContent>
                </Box>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={(): void => redirectToNew(newItem.slug)}
                    className={classes.textMore}
                  >
                    Continue lendo
                  </Button>
                </CardActions>
              </Linker>
            </Grid>
          ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="40px"
        >
          <Button
            className={classes.buttonMoreNews}
            onClick={() => history.push('/noticias')}
          >
            Ver todas
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
