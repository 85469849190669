import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchI } from '../../../SearchService';
import colorContext from '../../../../Context/colorContext';

interface Props {
  service: SearchI;
}

export default function ServiceDetails({ service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    information: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1040px',
      margin: '80px 0px',
      padding: '0px 10px',
      [theme.breakpoints.down(660)]: {
        padding: '0px 20px',
        marginTop: 40,
      },
    },
    iframe: {
      border: `1px solid ${colors.borderShadowsColor}`,
      borderRadius: 8,
      backgroundColor: '#F4EFE3',
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.information}>
        <iframe
          src={service.url_externo}
          width="100%"
          height="514px"
          title="servico_integrado"
          className={classes.iframe}
        />
      </Box>
    </Box>
  );
}
