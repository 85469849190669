/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchI } from '../../../SearchService';
import { ModalNotAuth } from '../../../../Components';
import colorContext from '../../../../Context/colorContext';
import { Params } from '../..';
import getCookie from '../../../../utils/getCookies';
import { getIntegrationActive } from '../../../../services/servico';

interface Props {
  service: SearchI;
  openModalSchedulerPresential: () => void;
  openModalSchedulerOnline: () => void;
}

interface AcessServiceProps {
  url: string;
  link_integrado: boolean;
  acesso_externo: boolean;
}

export default function ConsumeService({
  service,
  openModalSchedulerPresential,
  openModalSchedulerOnline,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      bottom: 0,
      backgroundColor: colors.colorBackground,
      borderTop: `1px solid ${colors.dividerColor}`,
      borderBottom: `1px solid ${colors.dividerColor}`,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        padding: '16px 0px',
      },

    },
    button: {
      borderRadius: 4,
      textTransform: 'capitalize',
      maxWidth: 336,
      width: '100%',
      height: 32,
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(16),
      backgroundColor: colors.accentColorBackground,
      '&:hover': {
        backgroundColor: colors.colorBackground,
        borderColor: colors.accentColor,
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        textAlign: 'center',
        marginBottom: 8,
      },
    },
    labelButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 14,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        textAlign: 'center',
      },
    },
    textTitle: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(32),
      color: colors.textBody,
      lineHeight: '38.4px',
      alignSelf: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '100%',
        fontSize: theme.typography.pxToRem(24),
        marginBottom: 32,
      },
    },
    subTitle: {
      color: colors.subTitleColor,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: '28.8px',
    },
    icon: {
      width: 32,
      height: 32,
      color: 'inherit',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 16,
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '12px 0px',
      alignSelf: 'normal',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '0px 24px',
      },
    },
    contentAlign: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
  }));
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [loadingIntegrationLink, setLoadingIntegrationLink] = useState<boolean>(true);
  const [hasIntegrationLink, setHasIntegrationLink] = useState<boolean>(false);
  const [integrationId, setIntegrationId] = useState<string>();

  const history = useHistory();
  const params: Params = useParams();

  const openSchedulerPresential = (): void => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      setIsAuth(true);
      openModalSchedulerPresential();
    } else {
      setIsAuth(false);
    }
  };

  const openSchedulerOnline = (): void => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      setIsAuth(true);
      openModalSchedulerOnline();
    } else {
      setIsAuth(false);
    }
  };

  function popupWindow(url: string, windowName: string, win: any, w: number, h: number): void {
    const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  }

  const openAccessService = ({ url, link_integrado, acesso_externo }: AcessServiceProps): void => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      if (hasIntegrationLink && integrationId) {
        setIsAuth(true);
        history.push(`/servicos_integrados/${service.categoria_slug}/${service.slug}/${service.id}/${integrationId}`);
        return;
      }

      if (acesso_externo) {
        setIsAuth(true);
        window.open(url, '_blank');
        return;
      }
      if (link_integrado) {
        history.push(`/servico-integrado/${params.slug}`);
      }
    } else {
      setIsAuth(false);
    }
  };


  const getIntegration = async (service_id: number) => {
    try {
      setLoadingIntegrationLink(true);
      const { data } = await getIntegrationActive(service_id);
      if (data) {
        setHasIntegrationLink(true);

        setIntegrationId(data.integracao_id);
      } else {
        setHasIntegrationLink(false);
      }
      setLoadingIntegrationLink(false);
    } catch (error) {
      setHasIntegrationLink(false);
      setLoadingIntegrationLink(false);
    }
  };

  useEffect(() => {
    if (service.id) {
      getIntegration(service.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  return (
    <Box className={classes.main}>
      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />
      <Box
        display="flex"
        maxWidth="1040px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box
          className={classes.content}
        >
          <Button
            disabled={(loadingIntegrationLink) || (!service.acesso_externo && !service.link_integrado && !hasIntegrationLink)}
            variant="contained"
            className={classes.button}
            classes={{
              label: classes.labelButton,
            }}
            onClick={() => openAccessService({ url: service.url_externo, acesso_externo: service.acesso_externo, link_integrado: service.link_integrado })}
          >
            Acessar serviço
            {' '}
            {loadingIntegrationLink && <CircularProgress size={10} />}
          </Button>
          <Button
            disabled={!service.online}
            variant="contained"
            className={classes.button}
            onClick={openSchedulerOnline}
            classes={{
              label: classes.labelButton,
            }}
          >

            Atendimento online
          </Button>
          <Button
            disabled={!service.agendavel}
            variant="contained"
            className={classes.button}
            classes={{
              label: classes.labelButton,
            }}
            onClick={openSchedulerPresential}
          >
            Atendimento presencial
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
