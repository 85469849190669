/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

interface ColorContextProps {
  typeTheme: string;
  colors: {
    accentColor: string;
    accentColorBackground: string;
    colorBackground: string;
    colorBackgroundSecundary: string;
    colorBackgroundTertiary: string;
    colorBackgroundQuaternary: string;
    colorBackgroundFooter: string;
    colorBackgroundFooterSecundary: string;
    colorBackgroundFooterTertiary: string;
    colorBackgroundNavSticky: string;
    colorBackgroundMainMenu: string;
    textBody: string;
    textSecondary: string;
    textAccentColor: string;
    textGrayStrong: string;
    textBlackGray: string;
    badgeColor: string;
    cardAccentColor: string;
    accordionTextColor: string;
    serviceCardBackgroundColor: string;
    favoriteBoxColor: string;
    breadcrumbButtonColorHover: string;
    borderShadowsColor: string;
    buttonSuccessColor: string;
    buttonErrorColor: string;
    accentColorSecondary: string;
    iconSocialColor: string;
    iconStepColor: string;
    stepLabelColor: string;
    menuItemColor: string;
    subTitleColor: string;
    dividerColor: string;
    boxShadowColor: string;

  };
  setTypeTheme?: (type: string) => void;
}

const colorContext = createContext<ColorContextProps>({
  typeTheme: 'normal',
  colors: {
    accentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    accentColorBackground: atob(process.env.REACT_APP_COLOR_PRIMARY),
    colorBackground: '#E8EBEF',
    colorBackgroundSecundary: '#ffffff',
    colorBackgroundTertiary: '#A7D046',
    colorBackgroundQuaternary: '#265AAA',
    colorBackgroundFooter: '#00519C',
    colorBackgroundFooterSecundary: '#043870',
    colorBackgroundFooterTertiary: '#73B0EE',
    colorBackgroundNavSticky: '#F8F8F8',
    colorBackgroundMainMenu: 'rgba(0, 57, 115, 0.95);',
    textBody: '#6B6D6E',
    textSecondary: '#FFFF1A',
    textGrayStrong: '#5E5E5E',
    textBlackGray: '#666666',
    textAccentColor: '#ffff',
    badgeColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    cardAccentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: '#94C120',
    iconStepColor: '#94C120',
    iconSocialColor: '#003F7E',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
    dividerColor: '#E4E7F0',
    boxShadowColor: '0px 85px 34px rgba(60, 76, 112, 0.01), 0px 21px 21px rgba(60, 76, 112, 0.09)',
  },
});

export default colorContext;
