/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import refContext from '../../Context/refContext';
import { searchServiceForOrgan } from '../../services/servico';
import { SearchII } from '../SearchService';
import {
  Breadcrumb,
  Services,
  Title,
} from './components';
import { OrgansI } from '../OrgansList';
import { getOrgan } from '../../services/orgao';

export default function ServiceListByOrganization(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [serviceList, setServiceList] = useState<SearchII>();
  const [loading, setLoading] = useState(true);
  const [organ, setOrgan] = useState<OrgansI>();
  const { slug }: {slug: string} = useParams();

  const { refInicio } = useContext(refContext);

  const getOrganEspecific = async (slug: string): Promise<void> => {
    setOrgan(undefined);
    setLoading(true);
    try {
      const { data } = await getOrgan(slug);
      setOrgan(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getServices = async () => {
    setLoading(true);
    const { data } = await searchServiceForOrgan(slug);
    const responseData: SearchII = {
      results: data,
      count: 0,
      next: 0,
      previous: 0,
      current: 0,
      total_pages: 0,
    };
    setServiceList(responseData);
    setLoading(false);
  };

  useEffect(() => {
    if (slug) {
      getOrganEspecific(slug);
      getServices();
    }
  }, [slug]);

  useEffect(() => {
    refInicio.current.scrollIntoView();
  }, [loading]);

  return (
    <div>

      <Breadcrumb slug={slug} orgao_nome={organ?.nome || '...'} title="Todos os serviços" />
      <Title orgao_nome={organ?.nome || '...'} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="600px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {serviceList?.results.length > 0 ? (
            <>

              <Services services={serviceList} />
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="600px"
            >
              <Typography
                style={{ fontSize: 25, color: colors.textBody }}
              >
                Nenhum serviço encontrado
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
}
