import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Card, CardActionArea, CardContent, CircularProgress, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { MdSearchOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { ES, ServiceProfileI } from '../Home';
import {
  Breadcrumb,
  Title,
} from './components';
import theme from '../../services/theme-service';
import colorContext from '../../Context/colorContext';
import { getServiceTypeUser } from '../../services/servico';

export interface Gestor {

  arquivo: string;
  ativo: boolean;
  biografia: string;
  nome: string;

}
export interface OrgansI {
  contatoOrgao: {
    email: string;
    facebook: string;
    instagram: string;
    telefone: string;
    twitter: string;
    whatsapp: string;
    youtube: string;
  };
  created_at: string;
  descricao: string;
  endereco_orgao: {
    bairro: string;
    cep: string;
    cidade: {
      slug: string;
      cidade: string;
      estado: string;
    };
    complemento: string;
    dias_semana: string;
    endereco: string;
    hora_fim: string;
    hora_inicio: string;
    source: string;
  };
  gestor: Gestor[];
  id: number;
  nome: string;
  sigla: string;
  slug: string;
  updated_at: string;
  link: string;
  missao: string;
  valores: string;
  visao: string;
}

const profileNamesMaps = {
  cidadao: 'Cidadão',
  servidor: 'Servidor',
  empresa: 'Pessoa Jurídica',
  gestao_publica: 'Gestão Pública',
};

export default function ServicesProfileList(): JSX.Element {
  const { colors } = useContext(colorContext);
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    boxInput: {
      width: '99%',
      display: 'flex',
    },
    boxInputSearch: {
      display: 'flex',
      justifyContent: 'center',
      padding: '64px 0px 48px 0px',
    },
    formSearch: {
      maxWidth: 512,
      borderRadius: 4,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 10px',
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    inputSearch: {
      width: '100%',
      height: 64,
      outline: 'none',
      paddingLeft: 16,
      fontFamily: 'Open sans',
      fontSize: 14,
      borderRadius: 4,
      border: 'none',
      margin: '0px 10px',
      backgroundColor: 'transparent',
      color: colors.subTitleColor,
      '&::-webkit-input-placeholder': {
        color: colors.subTitleColor,
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconButton: {
      borderRadius: 1,
      color: colors.accentColor,
      padding: 19,
      minWidth: 49,
      textAlign: 'center',
    },
    boxServicesSelected: {

      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    servicesSelected: {
      display: 'flex',
      alignItems: 'center',
      margin: '48px 0px 40px 0px',
      [theme.breakpoints.down('xs')]: {
        margin: '40px 0px',
        border: '1px solid #e3e3e3',
        flexWrap: 'wrap',
      },
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.textBody,
      fontWeight: 600,
      backgroundColor: colors.serviceCardBackgroundColor,
      textTransform: 'uppercase',
      padding: '15px 40px',
      textAlign: 'center',
      fontSize: 15,
      width: '100%',
      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '4px 0px 4px 0px',
      },
    },
    textTitleSelected: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColor,
    },
    servicesList: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'inherit',
      flexDirection: 'row',
      width: '100%',
      gridGap: 16,
      maxWidth: 1040,
      gap: 16,
      [theme.breakpoints.down(755)]: {
        justifyContent: 'center',
      },
    },
    card: {
      borderRadius: 4,
      width: 336,
      height: 180,
      color: colors.textAccentColor,
      background: colors.serviceCardBackgroundColor,
      borderBottom: `1px solid ${colors.accentColor}`,
      position: 'relative',
      '&:hover': {
        backgroundColor: 'rgba(0, 63, 126, 0.1)',
        transition: 'all 0.2s ease',
      },
    },
    cardContent: {
      padding: 24,
      height: '100%',
      borderBottom: `1px solid ${colors.accentColor}`,
    },
    serviceCard: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 5,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    textCategoryName: {
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        fontSize: theme.typography.pxToRem(13),
      },
    },
    textTitleInitial: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: '38.4px',
      marginBottom: 16,
      fontWeight: 800,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitleInitial: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    serviceIcon: {
      fontSize: 32,
      color: colors.accentColor,
    },
    textCard: {
      color: colors.textBody,
      fontWeight: 600,
      textAlign: 'start',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    boxWanted: {
      marginTop: 30,
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
    },
    wantedIcon: {
      width: 40,
      height: 40,
      color: colors.subTitleColor,
    },
    wanted: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      fontWeight: 600,
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceProfile, setServiceProfile] = useState<ServiceProfileI>();
  const [serviceSelected, setServiceSelected] = useState<
  'cidadao' | 'servidor' | 'empresa' | 'gestao_publica'
  >('cidadao');
  const matches = useMediaQuery('(min-width:755px)');
  const getServiceProfile = async (userType: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública') => {
    try {
      setLoading(true);
      const { data } = await getServiceTypeUser(userType);

      if (userType === 'Cidadão') {
        setServiceProfile((oldState) => ({
          ...oldState,
          cidadao: [...data],
        }));
      }
      if (userType === 'Servidor') {
        setServiceProfile((oldState) => ({
          ...oldState,
          servidor: [...data],
        }));
      }
      if (userType === 'Empresa') {
        setServiceProfile((oldState) => ({
          ...oldState,
          empresa: [...data],
        }));
      }
      if (userType === 'Gestão Pública') {
        setServiceProfile((oldState) => ({
          ...oldState,
          gestao_publica: [...data],
        }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log({ serviceProfile });
  }, [serviceProfile]);

  useEffect(() => {
    getServiceProfile('Cidadão');
    getServiceProfile('Servidor');
    getServiceProfile('Empresa');
    getServiceProfile('Gestão Pública');
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Lista de serviços em destaques" />
      <Title />
      <Box
        maxWidth="1040px"
        width="100%"
        alignSelf="center"
        minHeight="40vh"
      >
        <Box className={classes.boxServicesSelected}>
          <Box className={classes.servicesSelected}>
            <Typography
              onClick={(): void => setServiceSelected('cidadao')}
              className={
                serviceSelected === 'cidadao'
                  ? `${classes.textTitle} ${classes.textTitleSelected}`
                  : classes.textTitle
              }
              variant={matches ? 'h4' : undefined}
            >
              {matches ? (
                <>Cidadão</>
              ) : (
                <>Cidadão</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('servidor')}
              className={
                serviceSelected === 'servidor'
                  ? `${classes.textTitle} ${classes.textTitleSelected}`
                  : classes.textTitle
              }
              variant={matches ? 'h4' : undefined}
            >
              {matches ? (
                <>Servidor</>
              ) : (
                <>Servidor</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('empresa')}
              className={
                serviceSelected === 'empresa'
                  ? `${classes.textTitle} ${classes.textTitleSelected}`
                  : classes.textTitle
              }
              variant={matches ? 'h4' : undefined}
            >
              {matches ? (
                <>Pessoa Jurídica</>
              ) : (
                <>Pessoa Jurídica</>
              )}
            </Typography>
            <Typography
              onClick={(): void => setServiceSelected('gestao_publica')}
              className={
                serviceSelected === 'gestao_publica'
                  ? `${classes.textTitle} ${classes.textTitleSelected}`
                  : classes.textTitle
              }
              variant={matches ? 'h4' : undefined}
            >
              {matches ? (
                <>Gestão Pública</>
              ) : (
                <>Gestão Pública</>
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.servicesList}>
          {loading && (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
          )}

          {serviceProfile?.[serviceSelected]?.length > 0 && serviceProfile?.[serviceSelected]?.map((service) => (
            <Card
              onClick={(): void => history.push(`/servico/${service.slug}`)}
              key={service.id}
              className={classes.card}
            >
              <CardActionArea
                style={{
                  height: '100%',
                  borderBottom: `1px solid ${colors.accentColor}`,
                }}
              >
                <CardContent className={classes.cardContent}>
                  <Box
                    className={classes.serviceCard}
                  >
                    <Typography className={classes.textCategoryName}>
                      {service.categoria_nome}
                    </Typography>
                    <span
                      className={`material-icons ${classes.serviceIcon}`}
                    >
                      {service.categoria_icone}
                    </span>
                  </Box>
                  <Typography className={classes.textCard}>
                    {service.titulo}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
          {serviceProfile?.[serviceSelected]?.length === 0 && (
            <Box className={classes.boxWanted}>
              <MdSearchOff className={classes.wantedIcon} />
              <Typography className={classes.wanted}>
                Nenhum serviço em destaque para
                {profileNamesMaps[serviceSelected]}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

    </Box>
  );
}
