import {
  Box, CircularProgress, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import colorContext from '../../Context/colorContext';
import { filterOrgan } from '../../services/orgao';
import theme from '../../services/theme-service';
import { ES } from '../Home';
import {
  Breadcrumb,
  OrgansListComponent,
  PaginationService,
  Title,
} from './components';

export interface Gestor {

  arquivo: string;
  ativo: boolean;
  biografia: string;
  nome: string;

}
interface LinkInfo {
  titulo: string;
  link: string;
  ativo: boolean;
}

export interface OrgansI {
  contatoOrgao: {
    email: string;
    facebook: string;
    instagram: string;
    telefone: string;
    twitter: string;
    whatsapp: string;
    youtube: string;
  };
  sites: LinkInfo[],
  created_at: string;
  descricao: string;
  endereco_orgao: {
    bairro: string;
    cep: string;
    cidade: {
      slug: string;
      cidade: string;
      estado: string;
    };
    complemento: string;
    dias_semana: string;
    endereco: string;
    hora_fim: string;
    hora_inicio: string;
    intervalo_ativo: boolean;
    hora_inicio_manha: string;
    hora_inicio_tarde: string;
    hora_fim_manha: string;
    hora_fim_tarde: string;
    atendimento_hora_inicio_manha?: string;
    atendimento_hora_fim_manha?: string;
    atendimento_hora_inicio_tarde?: string;
    atendimento_hora_fim_tarde?: string;
    source: string;
  };
  gestor: Gestor[];
  id: number;
  nome: string;
  sigla: string;
  slug: string;
  updated_at: string;
  link: string;
  missao: string;
  valores: string;
  visao: string;
}

export interface OrgansII extends ES {
  results: OrgansI[];
}

export default function OrgansList(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    boxInput: {
      width: '99%',
      display: 'flex',
    },
    boxInputSearch: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0px 20px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0px 20px 0px',
      },
    },
    formSearch: {
      maxWidth: 512,
      borderRadius: 4,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 10px',
      backgroundColor: colors.serviceCardBackgroundColor,
      [theme.breakpoints.down('sm')]: {
        margin: '10px 16px',
      },
    },
    inputSearch: {
      width: '100%',
      height: 64,
      outline: 'none',
      paddingLeft: 16,
      fontFamily: 'Open sans',
      fontSize: 14,
      borderRadius: 4,
      border: 'none',
      margin: '0px 10px',
      backgroundColor: 'transparent',
      color: colors.subTitleColor,
      '&::-webkit-input-placeholder': {
        color: colors.subTitleColor,
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconButton: {
      borderRadius: 1,
      color: colors.accentColor,
      padding: 19,
      minWidth: 49,
      textAlign: 'center',
    },
  }));

  const classes = useStyles();
  const [listOrgans, setListOrgans] = useState<OrgansII>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);

  const getOrgans = async ({ page, search }: { page?:number; search?: string; }) => {
    (window as any).scrollTo(0, 0);
    setListOrgans(undefined);
    setLoading(true);
    if (search) {
      setIsSearch(true);
    }
    try {
      const { data } = await filterOrgan({ page, search });
      if (data && data.results) {
        data.results.sort((a: OrgansII['results'][0], b: OrgansII['results'][0]) => a.nome.localeCompare(b.nome));
      }
      setListOrgans(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleClear = () => {
    getOrgans({ page: 1 });
    setTextSearch('');
    setIsSearch(false);
  };

  useEffect(() => {
    getOrgans({ page: 1 });
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Lista de Órgãos" />
      <Title organs_list={listOrgans} />
      <Box className={classes.boxInputSearch}>
        <Box
          style={{
            maxWidth: 1040,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (textSearch) {
                getOrgans({ search: textSearch.toLowerCase() });
              }
            }}
            className={classes.formSearch}
          >
            <div className={classes.boxInput}>
              {!isSearch ? (
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                >
                  <MdSearch />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleClear}
                  type="button"
                  className={classes.iconButton}
                >
                  <FaTimes />
                </IconButton>
              )}

              <input
                value={textSearch}
                onChange={(e): void => setTextSearch(e.target.value)}
                className={classes.inputSearch}
                placeholder="Buscar órgão"
              />
            </div>
          </form>
        </Box>
      </Box>

      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {listOrgans?.results.length && !loading ? (
        <>
          <OrgansListComponent listOrgans={listOrgans} />
          <PaginationService getOrgans={getOrgans} listOrgans={listOrgans} />
        </>
      ) : null}
      {(listOrgans?.results.length === 0 && !loading) && (
        <Box
          marginBottom="60px"
          minHeight="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{ textAlign: 'center', color: colors.textBody }}
            variant="h4"
          >
            Nenhum órgão encontrado
          </Typography>
        </Box>
      )}
    </Box>
  );
}
