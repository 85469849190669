import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { MdSearchOff } from 'react-icons/md';
import { apisiseci } from '../../services/api';
import { ES } from '../Home';
import {
  Breadcrumb,
  ListNews,
  PaginationService,
  ServiceList, Title,
} from './components';
import { SearchI } from '../SearchService';
import { serviceForCategory, serviceSubjct } from '../../services/servico';
import { News } from '../News';
import { getNewForCategory } from '../../services/noticias';
import colorContext from '../../Context/colorContext';

interface Params {
  slug: string;
}

export interface SearchII extends ES {
  results: SearchI[];
}

export interface CategoryII {
  id: number;
  slug: string;
  titulo: string;
  icone: string;
}

export default function SubjectService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      maxWidth: '1040px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.textBody,
      fontWeight: 600,
      backgroundColor: colors.serviceCardBackgroundColor,
      textTransform: 'uppercase',
      padding: '8px 0px',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(15),
      width: '100%',

      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    textTitleSelected: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColor,
    },
    iconNotFound: {
      width: 80,
      height: 80,
      color: colors.subTitleColor,
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [category, setCategory] = useState<CategoryII>();
  const [loading, setLoading] = useState<boolean>(false);
  const haveNews = process.env.REACT_APP_HAVE_NOTICIAS;
  const [
    tabSelected,
  ] = useState<'servicos' | 'noticias'>('servicos');

  const [getNew, setGetNew] = useState<News>();

  const getSearch = async (page: number) => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await serviceSubjct(params.slug, page);
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCategories = async () => {
    const { data } = await apisiseci.get(`/cms/topicos/?slug=${params.slug}`);
    if (data?.results) {
      setCategory(data.results.filter((item: any) => item.slug === params.slug)[0]);
    }
  };


  useEffect(() => {
   getCategories();
    getSearch(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);
  return (
    <Box className={classes.main}>
      {category && (
        <>
          <Breadcrumb slug={category?.titulo} />
          <Title slug={category?.titulo} />
        </>
      )}
      {true ? (
        <>
          <Box marginTop="64px" width="100%" display="flex" alignItems="center" justifyContent="center">
            <Box className={classes.content}>
              
            </Box>
          </Box>
          {listSearch && listSearch?.results.length > 0 && tabSelected === 'servicos' && (
            <>
              <ServiceList listSearch={listSearch} />
              <PaginationService
                getSearch={getSearch}
                listSearch={listSearch}
              />
            </>
          )}
          
        </>
      ) : null}
      {loading && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {(listSearch?.results.length === 0 && !loading && tabSelected === 'servicos') && (
        <Box
          minHeight="500px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <MdSearchOff className={classes.iconNotFound} />
          <Typography
            style={{ textAlign: 'center', marginTop: 24, color: colors.textBody }}
            variant="h4"
          >
            Nenhum serviço encontrado
          </Typography>
        </Box>
      )}
      
    </Box>
  );
}
