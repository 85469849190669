import {
  Box, CircularProgress, Link, Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { MdSearchOff } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import adapterNews from '../../services/adapters/adapterNews';
import { searchNew } from '../../services/noticias';
import { searchServiceNew } from '../../services/servico';
import { ES } from '../Home';
import { News } from '../News';
import {
  Breadcrumb,
  ListNews,
  PaginationService,
  ServiceList, Title,
} from './components';

interface Params {
  slug: string;
}

export interface ServicoUnidadeI {
  id: number;
  agendamento: boolean;
  atendimento: boolean;
  unidade: {
    ativo: boolean;
    bairro: string;
    complemento: string;
    endereco: string;
    nome: string;
    slug_unidade: string;
    source: string;
  };
}

interface JornadaI {
  id: number;
  titulo: string;
  ordem: number;
  conteudo: string;
}

interface Motivos {
  id: number;
  titulo: string;
  ativo: boolean;
}
interface ConfigAgendamento {
  id: number;
  descricao_anexo: string;
  descricao_problema: string;
  anexo: boolean;
  texto: string
  motivos: Motivos[];
}

export interface SearchI {
  acesso_externo: boolean;
  agendavel: boolean;
  categoria_icone: string;
  categoria_nome: string;
  categoria_slug: string;
  created_at: string;
  custo: string;
  descricao: string;
  id: number;
  informacoes_extra: string;
  jornada: JornadaI[];
  online: boolean;
  orgao_nome: string;
  orgao_sigla: string;
  orgao_slug: string;
  publico: string;
  publico_especifico: ['Cidadão' | 'Servidor' | 'Empresa'];
  requisitos: string;
  servicosUnidade: ServicoUnidadeI[];
  configuracao_agendamento: ConfigAgendamento;
  setor_id: string;
  slug: string;
  tempo: number;
  tempo_online: number;
  tempo_total: number;
  tipo_tempo: string;
  titulo: string;
  link_integrado: boolean;
  total_avaliacao: number;
  url_externo: string;
  updated_at: string;
  ultima_revisao: string;
}

export interface SearchII extends ES {
  results: SearchI[];
}

export default function SearchService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.textBody,
      fontWeight: 600,
      backgroundColor: colors.serviceCardBackgroundColor,
      textTransform: 'uppercase',
      padding: '8px 0px',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(15),
      width: '100%',

      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    textTitleSelected: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColor,
    },
    iconNotFound: {
      width: 80,
      height: 80,
      color: colors.subTitleColor,
    },
    content: {
      width: '100%',
      maxWidth: '1040px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
    buttonMoreNews: {
      color: colors.accentColor,
      padding: '8px 39px',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '24px',
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      textDecoration: 'none',
      marginBottom: 20,

      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
        textDecoration: 'none',
      },
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [loadingNews, setLoadingNews] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [getNew, setGetNew] = useState<News>();
  const haveNews = atob(process.env.REACT_APP_HAVE_NOTICIAS);
  const [
    tabSelected,
    setTabelected,
  ] = useState<'servicos' | 'noticias'>('servicos');

  const getSearch = (page: number) => {
    setListSearch(undefined);
    try {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.execute(atob(process.env.REACT_APP_RECAPTCHA), { action: 'submit' }).then(async (token) => {
          if (token) {
            const { data } = await searchServiceNew(params.slug, page).finally(() => {
              setLoadingSearch(false);
            });
            setListSearch(data);
          }
        });
      });
    } catch (err) {
      console.log(err);
      setLoadingSearch(false);
    }
  };
  const getNews = async (page: number) => {
    setGetNew(undefined);
    try {
      const { data } = await searchNew(params.slug, page);
      const newsData = await adapterNews(data, 'wp_v2');
      setGetNew(newsData);
      setLoadingNews(false);
    } catch (err) {
      setLoadingNews(false);
    }
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${atob(process.env.REACT_APP_RECAPTCHA)}`;
    script.addEventListener('load', () => {
      getSearch(1);
      getNews(1);
    });
    document.body.appendChild(script);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug={params.slug} />
      <Title slug={params.slug} />
      <>
        <Box marginTop="64px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <Box className={classes.content}>
            <Box className={classes.boxActions}>
              <Typography
                onClick={(): void => setTabelected('servicos')}
                className={
                  tabSelected === 'servicos'
                    ? `${classes.textTitle} ${classes.textTitleSelected}`
                    : classes.textTitle
                }
              >
                Serviços (
                {listSearch ? listSearch.results.length : 0 }
                )
              </Typography>
              {haveNews === 'true' && (
              <Typography
                onClick={(): void => setTabelected('noticias')}
                className={
                  tabSelected === 'noticias'
                    ? `${classes.textTitle} ${classes.textTitleSelected}`
                    : classes.textTitle
                }
              >
                Notícias (
                {
                  getNew ? getNew.results.length : 0
                }
                )
              </Typography>
              )}
            </Box>
          </Box>
        </Box>
        {listSearch && listSearch.results.length > 0 && tabSelected === 'servicos' && (
        <>
          <ServiceList listSearch={listSearch} />
          <PaginationService
            getSearch={getSearch}
            listSearch={listSearch}
          />
        </>
        )}
        {haveNews === 'true' && getNew && getNew.results.length > 0 && tabSelected === 'noticias' && (
        <>
          <ListNews listNews={getNew} />
            {atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true'
              ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop="40px"
                >

                  <Link
                    className={classes.buttonMoreNews}
                    href={
                    `${atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_URL)}/?s=${params.slug}`
}
                    target="_blank"
                  >
                    Ver mais notícias
                  </Link>
                </Box>
              )
              : (
                <PaginationService
                  getSearch={getNews}
                  listSearch={getNew}
                />
              )}
        </>
        )}
      </>
      {((loadingSearch && tabSelected === 'servicos') || (loadingNews && tabSelected === 'noticias')) && (
        <Box
          margin="60px 0px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress style={{ color: colors.accentColor }} />
        </Box>
      )}
      {(listSearch?.results.length === 0 && !loadingSearch && tabSelected === 'servicos') && (
      <Box
        minHeight="500px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MdSearchOff className={classes.iconNotFound} />
        <Typography
          style={{ textAlign: 'center', marginTop: 24, color: colors.textBody }}
          variant="h4"
        >
          Nenhum serviço encontrado
        </Typography>
      </Box>
      )}
      {(getNew && getNew?.results && getNew?.results?.length === 0 && !loadingNews && tabSelected === 'noticias') && (
      <Box
        minHeight="500px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MdSearchOff className={classes.iconNotFound} />
        <Typography
          style={{ textAlign: 'center', marginTop: 24, color: colors.textBody }}
          variant="h4"
        >
          Nenhuma notícia encontrada
        </Typography>
      </Box>
      )}
    </Box>
  );
}
