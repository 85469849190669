import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Close, DeleteForever,
} from '@material-ui/icons';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';

interface Props {
  open: boolean;
  handleClose: () => void;
  files: FileObject[];
  setFiles: (file: FileObject[]) => void;
  sendArquive: () => void;
  loadingFiles: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  formControl: {
    marginBottom: 20,
    margin: theme.spacing(1),
    width: '97%',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#737B7D',
  },
  textSchedule: {
    color: '#373F41',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: '#373F41',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: '#9E9E9E',
  },
  rootUpload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 140,
    flexDirection: 'column',
  },
  adornedEnd: {
    paddingRight: 0,
  },
  multilineColor: {
    color: '#000',
  },
}));

export default function ModalSendFiles({
  open,
  handleClose,
  files,
  setFiles,
  sendArquive,
  loadingFiles,
}: Props): JSX.Element {
  const classes = useStyles();

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter((fileFiltering) => fileFiltering.data !== file.data);
    setFiles(fileFilter);
  };

  useEffect(() => {
    setFiles([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        className={classes.buttonClose}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <div
          style={{
            color: '#9E9E9E',
            marginTop: 50,
            marginLeft: 10,
            width: '97%',
          }}
        >
          <DropzoneAreaBase
            onAdd={(filesUpload) => {
              setFiles(filesUpload);
            }}
            fileObjects={files}
            acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
            showPreviewsInDropzone={false}
            dropzoneText="Faça upload ou solte arquivos aqui"
            showFileNames={false}
            showFileNamesInPreview={false}
            showAlerts={false}
            filesLimit={10}
            classes={{
              icon: classes.iconUpload,
              root: classes.rootUpload,
            }}
          />
          {files.map((file) => (
            <Card
              key={file.data?.toString()}
              style={{
                width: '100%',
                color: '#373F41',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 10px',
                marginBottom: 2,
              }}
            >
              {file.file.name}
              <IconButton onClick={(): void => removeFile(file)}>
                <DeleteForever style={{ color: 'red' }} />
              </IconButton>
            </Card>
          ))}
        </div>

      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 15,
        }}
      >
        <Button
          disabled={files.length <= 0 || loadingFiles}
          style={{
            textTransform: 'none',
            color: '#fff',
            borderRadius: 0,
            minWidth: 200,
            marginTop: 20,
          }}
          color="secondary"
          variant="contained"
          onClick={sendArquive}
        >
          {loadingFiles ? <CircularProgress style={{ width: 25, height: 25 }} color="secondary" /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
