import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  btnCancel: {
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    backgroundColor: '#fff',
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
  btnConfirm: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  dialogContent: {
    padding: theme.spacing(2, 3, 0, 3),
  },
  dialogTitle: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  confirmationText: {
    fontWeight: 'bold',
  },
}));

function GuidanceModal({
  open,
  handleClose,
  handleConfirm,
  description,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ marginBottom: 0 }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography className={classes.confirmationText}>
          Deseja continuar com o agendamento?
        </Typography>
        <Box>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.btnCancel}
          >
            Não
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            className={classes.btnConfirm}
            autoFocus
          >
            Sim
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default GuidanceModal;
