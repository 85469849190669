import React from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 600,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function NotFound(): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <img
        style={{ maxWidth: '70vw', height: 'auto' }}
        alt="not found"
        src="/assets/images/404.svg"
      />
    </Box>
  );
}
