import React, { useContext } from 'react';
import {
  Box, Card, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';
import { SearchII } from '../..';
import colorContext from '../../../../Context/colorContext';
import RatingEvaluation from '../../../../Components/RatingEvaluation';

interface Props {
  listSearch: SearchII;
}

export default function ServiceList({
  listSearch,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      marginTop: 48,
    },
    card: {
      width: '100%',
      minHeight: 154,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 2,
      padding: 14,
      backgroundColor: colors.colorBackground,
      textDecoration: 'none',
      border: `1px solid ${colors.colorBackground}`,
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.16)',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    boxTexts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        width: '100%',
      },
    },
    boxTags: {
      display: 'flex',
      width: '30%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        width: '100%',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 700,
      color: colors.textBody,
      textAlign: 'left',
      '&:hover': {
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    subTitle: {
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      color: colors.accentColor,
      marginBottom: 16,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
      },
    },
    contentTag: {
      marginTop: '13px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      gridGap: '8px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    tag: {
      fontFamily: 'Open Sans',
      backgroundColor: colors.colorBackground,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(10),
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: '5px 14px',
      borderRadius: 25,
      border: `1px solid ${colors.accentColor}`,
    },
    tagSuccess: {
      fontFamily: 'Open Sans',
      backgroundColor: colors.colorBackground,
      color: '#2FAB4D',
      fontSize: theme.typography.pxToRem(10),
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: '5px 14px',
      borderRadius: 25,
      border: '1px solid #2FAB4D',
    },
    textAvaliacao: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        marginTop: 12,
      },
    },
    starIcon: {
      color: '#FFA000',
      width: 16,
      height: 16,
    },
    emptyIcon: {
      color: 'rgba(51, 51, 51, 0.32)',
      width: 16,
      height: 16,
    },
    contentInfo: {
      marginTop: '29px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        {listSearch.results.map((search) => (
          <Link
            key={search.id}
            className={classes.card}
            to={`/${search.categoria_slug}/${search.slug}`}
          >
            <Box className={classes.boxTexts}>
              <Typography className={classes.subTitle} variant="h3">
                {search.orgao_nome}
              </Typography>
              <Typography className={classes.title} variant="h2">
                {search.titulo}
              </Typography>
              <Box
                className={classes.contentInfo}
              >
                {/* <Box display="flex" alignItems="center" gridGap="7px">
                <Typography className={classes.textAvaliacao}>
                  Avaliação:
                </Typography>

                <RatingEvaluation
                  value={search?.total_avaliacao}
                  readOnly
                  size="small"
                />
                <Typography className={classes.textAvaliacao}>
                  (
                  {search.total_avaliacao}
                  )
                </Typography>
              </Box> */}

              </Box>
            </Box>
            <Box className={classes.boxTags}>
              <Box
                className={classes.contentTag}
              >
                {search.agendavel && (
                <Typography className={classes.tagSuccess}>Agendável</Typography>
                )}
                {search.online && (
                  <Typography className={classes.tagSuccess}>Digital</Typography>
                )}

              </Box>
              <Box
                className={classes.contentTag}
              >
                {search.publico_especifico.length && search.publico_especifico.map((publico) => (
                  <Typography className={classes.tag}>{publico}</Typography>
                ))}
              </Box>

            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
