import React, { useContext, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdHome } from 'react-icons/md';
import { IoMdSearch } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
}

export default function Breadcrumb({
  slug,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      borderTop: `1px solid ${colors.colorBackgroundFooter}`,
    },
    link: {
      display: 'flex',
    },
    icon: {
      color: colors.accentColor,
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    formSearch: {

    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      fontFamily: 'Open sans',
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.serviceCardBackgroundColor,
      '&::placeholder': {
        color: '#888888',
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(14),
      },
    },
    buttonSearch: {
      background: colors.serviceCardBackgroundColor,
      '&:hover': {
        background: colors.breadcrumbButtonColorHover,
      },
    },
    breadcrumbs: {
      flexGrow: 1,
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        padding: '15px 16px 0px 16px',
      },
    },
    breadcrumbLi: {
      display: 'flex',
    },
    itemBreadcrumb: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
    iconSearch: {
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState < string >('');
  const matches = useMediaQuery('(min-width:935px)');
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      history.push(`/buscar/q=${encodeURIComponent(textSearch)}`);
      setTextSearch('');
    }
  };
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1040px"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        justifyContent={matches ? 'space-between' : 'flex-end'}
      >
        <Breadcrumbs separator="/" className={classes.breadcrumbs} classes={{ li: classes.breadcrumbLi }}>
          <MdHome className={classes.icon} onClick={(e: React.SyntheticEvent) => { e.preventDefault(); history.push('/'); }} />
          <Typography className={classes.itemBreadcrumb}>
            {slug}
          </Typography>
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: 'flex',
            justifyContent: `${(matches) ? 'flex-end' : 'center'}`,
            flexGrow: 1,
            minWidth: 300,
            marginTop: 15,
            marginBottom: 15,
            padding: '0px 16px',
          }}
        >
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              width: '100%',
              borderRadius: '4px 0px 0px 4px',
            }}
            placeholder="Digite aqui o que procura..."
          />
          <IconButton
            type="submit"
            className={classes.buttonSearch}
            style={{
              borderRadius: '0px 4px 4px 0px',
            }}
          >
            <IoMdSearch className={classes.iconSearch} />
          </IconButton>
        </form>
      </Box>
    </Box>
  );
}
