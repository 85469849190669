import React, { useContext, useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

interface Props {
  image: string;
  video: string;
  detailImage: string;
  detailVideo: string;
  mediaType: 'image' | 'video';
}

export default function ImageOrVideo({
  image,
  video,
  detailVideo,
  detailImage,
  mediaType,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxWidth: '1040px',
      marginTop: 64,
      paddingBottom: '40px',
      backgroundColor: colors.colorBackground,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
      },
    },
    content: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
    detail: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 400,
      textAlign: 'center',
      marginTop: 16,
    },
    img: {
      width: '100%',
      borderRadius: 5,
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        {mediaType === 'image' && (
          <img src={image} alt="imagem" className={classes.img} />
        )}
        {mediaType === 'video' && (
          <iframe
            src={`https://www.youtube.com/embed/${video}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            width="100%"
            height="500"
            allowFullScreen
            title="video"
          />
        )}
        <Typography className={classes.detail}>
          {mediaType === 'image' ? detailImage : detailVideo}
        </Typography>
      </Box>
    </Box>
  );
}
