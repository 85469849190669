import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Breadcrumb,
  Author,
  Title,
  ImageOrVideo,
  ContentNew,
  OriginNew,
  ContentRelation,
  ActionsNew,
} from './components';
import { News, NewsI } from '../News';
import { getNew, getNewForCategory, getNewForCategoryById } from '../../services/noticias';
import colorContext from '../../Context/colorContext';
import adapterNews from '../../services/adapters/adapterNews';

interface Params {
  slug: string;
}

export default function ServiceInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: '70vh',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',

    },
    divider: {
      display: 'block',
      height: 1,
      width: '100%',
      backgroundColor: colors.dividerColor,
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [newDetail, setNewDetail] = useState < NewsI >();
  const [newForCategory, setNewForCategory] = useState < News >();
  const [loading, setLoading] = useState < boolean >(false);
  const [mediaType, setMediaType] = useState<'image' | 'video'>('image');

  const getNewEspecific = async () => {
    setNewDetail(undefined);
    setLoading(true);

    try {
      const { data } = await getNew(params.slug);

      const newsData = await adapterNews(data, 'wp_v2');

      setNewDetail(newsData?.results[0] || {});
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getNewForCategorySlug = async (category_slug: string) => {
    setNewForCategory(undefined);
    setLoading(true);
    try {
      const { data } = await getNewForCategory(category_slug, 1);
      setNewForCategory(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getNewForCategoryId = async (category_id: number) => {
    setNewForCategory(undefined);

    setLoading(true);
    try {
      const { data } = await getNewForCategoryById(category_id, 1);

      const newsData = await adapterNews(data, 'wp_v2');
      setNewForCategory(newsData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChangeMediaType = (type: 'image' | 'video') => {
    setMediaType(type);
  };

  useEffect(() => {
    getNewEspecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    if (newDetail) {
      if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true' && newDetail?.categoria_id) {
        getNewForCategoryId(newDetail.categoria_id);
      } else {
        getNewForCategorySlug(newDetail.categoria_slug);
      }
    }
  }, [newDetail]);

  const url: string = (window as any).location;

  return (
    <Box className={classes.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{newDetail?.titulo}</title>
        <link rel="canonical" href={url} />
        <meta property="og:image" content={newDetail?.imagem_destaque_url} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:title" content={newDetail?.titulo} />
        <meta property="og:url" content={url} />
        <meta name="author" content="DC4G" />
      </Helmet>
      {loading && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {newDetail && !loading && newForCategory?.results.length && (
        <>
          <Breadcrumb slug={newDetail.titulo} />
          <Title category={newDetail.categoria_titulo} title={newDetail.titulo} />
          <Author
            author={newDetail.autor_nome}
            created={newDetail.created_at}
          />
          <ActionsNew changeMediaType={handleChangeMediaType} video={newDetail.youtube_videoId} image={newDetail.imagem_destaque_url} />
          <ImageOrVideo
            detailImage={newDetail.descricao_imagem}
            detailVideo={newDetail.descricao_video}
            image={newDetail.imagem_destaque_url}
            video={newDetail.youtube_videoId}
            mediaType={mediaType}
          />
          <ContentNew content={newDetail.conteudo} />
          <OriginNew
            organ={newDetail.orgao_nome}
            category={newDetail.categoria_titulo}
            tags={newDetail.tags_noticia}
          />
          <span className={classes.divider} />
          <ContentRelation newsRelation={newForCategory} />
        </>
      )}
    </Box>
  );
}
