/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { IoTimeOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { getAllNews } from '../../services/noticias';
import { getOrgan, listAllOrgan } from '../../services/orgao';
import { searchServiceForOrgan } from '../../services/servico';
import { ES } from '../Home';
import { News } from '../News';
import { OrgansI } from '../OrgansList';
import { SearchI } from '../SearchService';
import {
  Breadcrumb,
  PaginationService,
  ServiceList,
  Title,
} from './components';

interface Params {
  slug: string;
}
export interface SearchII extends ES {
  results: SearchI[];
}

export interface OrgansII extends ES {
  results: OrgansI[];
}

interface OrgansListProps {
  id: number;
  nome: string;
  sigla: string;
  slug: string;
}

export default function OrganListServices(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiOutlinedInput-root': {
        color: theme.palette.primary.main,
        '&:hover fieldset': {
          borderColor: '#d3d3d3',
        },
      },
    },
    main: {
      width: '100%',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(20),
      marginBottom: 5,
    },
    formControl: {
      marginTop: 20,
      marginBottom: 20,
      width: '100%',
    },
    titleName: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      textAlign: 'left',
      marginTop: 24,
      marginBottom: 16,
      color: colors.textBody,
    },
    text: {
      fontSize: 16,
      color: colors.textBody,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    description: {
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0px',
      textAlign: 'justify',
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
    },
    iconContact: {
      cursor: 'pointer',
      marginRight: 10,
      color: 'rgba(15, 111, 183, 0.5)',
      fontSize: theme.typography.pxToRem(16),
    },
    card: {
      maxWidth: 360,
      width: '100%',
      minHeight: 48,
      borderRadius: 0,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '0px 10px 0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 2,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
      },
    },
    textCard: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '340px',
      textTransform: 'capitalize',
    },
    menuOption: {
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      whiteSpace: 'normal',
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
      backgroundColor: colors.colorBackground,
    },
    accordionDetailLink: {
      padding: '16px ',
      wordBreak: 'break-word',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    accordion: {
      marginBottom: 26,
      width: '100%',
      boxShadow: colors.boxShadowColor,
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
      backgroundColor: colors.colorBackground,
      fontWeight: 700,
    },
    boxGestor: {
      width: '100%',
    },
    img: {
      width: 100,
      height: 100,
      borderRadius: '50%',
    },
    contatoGestor: {
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'justify',
      color: '#333333',
    },
    contatoGestorText: {
      fontWeight: 400,
    },
    links: {
      color: colors.accentColor,
      fontWeight: 400,
      fontSize: 16,
      marginLeft: -9,
    },
    buttonAgenda: {
      marginTop: 32,
      marginBottom: 40,
      backgroundColor: theme.palette.primary.main,
      height: 44,
      borderRadius: 4,
      padding: '10px 24px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    buttonAgendaText: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
    },
    textHorario: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0,
      textAlign: 'left',
      color: colors.textBody,
    },
    btnSocial: {
      color: colors.accentColor,
      margin: '0 -4px',
    },
    textTitle: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0,
      textAlign: 'left',
      color: colors.textBody,
      margin: '12px 0px',
    },
    textSubTitle: {
      fontWeight: 400,
      marginLeft: 3,
    },
    boxMap: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    boxService: {
      width: '100%',
    },
    barDivider: {
      width: '100%',
      marginTop: 8,
      height: 4,
      backgroundColor: colors.textBody,
      marginBottom: 30,
    },
    fullTitle: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(32),
      color: colors.textBody,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subTitleService: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    titleModal: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
    },
    titleModalText: {
      color: theme.palette.text.primary,
      fontSize: 14,
      marginLeft: 5,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    menuItem: {
      color: theme.palette.primary.main,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    boxAgenda: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
      minHeight: 96,
      margin: '8px 0px',
      height: 'auto',
      padding: 16,
    },
    boxInfoAgenda: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16,
      marginBottom: 10,
      maxWidth: 290,
      justifyContent: 'space-between',
    },
    textTitleLink: {
      margin: '8px 0',
      fontSize: '1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    textSubTitleLink: {
      marginLeft: 8,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    titleAgendaText: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    subTitleAgendaText: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    buttonReturnModal: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      textAlign: 'left',
      color: '#333333',
      textTransform: 'uppercase',
      padding: 20,
    },
    Card: {
      [theme.breakpoints.down('sm')]: {
        padding: '64px 16px',
      },
    },
    boxInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '10px',
      backgroundColor: colors.colorBackground,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    boxFormSearch: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      paddingTop: 12,
      [theme.breakpoints.down('sm')]: {
        padding: '10px 16px 0px 16px',
      },
    },
    iconMenu: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: theme.typography.pxToRem(10),
      },
    },
    formSearch: {
      width: '1040px',
      padding: '10px',
      margin: '40px 0px 0px 0px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E6E6E6',
      [theme.breakpoints.down('md')]: {
        width: 'calc(1040px - 46px)',
        display: '100% !important',
        padding: '6px 4px',
      },
    },
    containerClearButton: {
      width: '1040px',
      padding: '10px',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'right',
      [theme.breakpoints.down('md')]: {
        width: 'calc(1040px - 46px)',
        display: '100% !important',
        padding: '6px 4px',
      },
    },
    iconButton: {
      borderRadius: '10px',
      minWidth: 190,
      textAlign: 'center',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(13),
      textTransform: 'capitalize',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
      height: 45,
      [theme.breakpoints.down('sm')]: {
        minWidth: '40%',
        fontSize: theme.typography.pxToRem(11),
      },
    },
    inputSearch: {
      width: '100%',
      outline: 'none',
      paddingLeft: 25,
      fontFamily: 'Open Sans',
      fontSize: 14,
      borderRadius: '10px 0px 0px 10px',
      color: '#0057AF',
      opacity: 1,
      '&::placeholder': {
        color: '#0057AF',
        opacity: 1,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
      border: 'none',
      height: 45,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: '5px 0px 0px 5px',
      },
    },

    searchIcon: {
      position: 'absolute',
      left: '10px',
      fontSize: '21px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'blue',
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      },
    },
    divider: {
      height: 1,
      display: 'block',
      width: '100%',
      backgroundColor: colors.dividerColor,
    },
  }));

  const classes = useStyles();
  const params: Params = useParams();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [organ, setOrgan] = useState<OrgansI>();
  const [listOrgan, setListOrgan] = useState<OrgansListProps[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [showClearButton, setShowClearButton] = useState(false);

  const getListOrgan = async (): Promise<void> => {
    setListOrgan(undefined);
    setLoading(true);
    try {
      const { data } = await listAllOrgan();
      setListOrgan(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getOrganEspecific = async (slug: string): Promise<void> => {
    setOrgan(undefined);
    setLoading(true);
    try {
      const { data } = await getOrgan(slug);
      setOrgan(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getSearch = async (page: number, search?: string): Promise<void> => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await searchServiceForOrgan(params.slug, page, search);
      setListSearch(data);
      setLoading(false);
      setSearching(false)
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSearch(1);
    getOrganEspecific(params.slug);
    getListOrgan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);


   const getSearchWithFilter = (event) => {
    event.preventDefault();
    setSearching(true);
    setShowClearButton(true);
    getSearch(1, event.target.value || textSearch)
  };

  const clearSearch = () => {
    setTextSearch('');
    setShowClearButton(false);
    getSearch(1)
  };

  return (
    <Box className={classes.main}>
      {organ && listOrgan && (
        <>
          <Breadcrumb slug={organ.nome} />
          <Title organ={organ} />
        </>
      )}

      <Box className={classes.boxFormSearch}>
        <>
          <form onSubmit={(e) => getSearchWithFilter(e)} className={classes.formSearch}>
            <div className={classes.boxInput}>
              <Input
                onChange={(e) => setTextSearch(e.target.value)}
                value={textSearch}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getSearchWithFilter(e);
                  }
                }}
                className={classes.inputSearch}
                placeholder="Ex: CNH, Matrícula escolar..."
                disableUnderline
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      alt="Contraste"
                      src="/assets/images/searchIcon.svg"
                      className={classes.searchIcon}
                    />
                  </InputAdornment>
                }
              />
              <Button type="submit" className={classes.iconButton} disabled={searching || !textSearch}>
                BUSCAR SERVIÇO
              </Button>
            </div>
          </form>
        </>
      </Box>
      <Box className={classes.boxFormSearch}>
        <div className={classes.containerClearButton}>
        {showClearButton &&
          <Typography
            onClick={clearSearch}
            variant='body2'
            style={{ cursor: 'pointer', marginTop: '8px', textAlign: 'right', maxWidth: '100px' }}
          >
            LIMPAR FILTRO
          </Typography>
        }
        </div>
      </Box>

      {loading && (
        <Box
          marginBottom="60px"
          marginTop="120px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        display="flex"
        maxWidth="1040px"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        {listSearch?.results.length ? (
          <>
            <ServiceList listSearch={{ ...listSearch, results: listSearch?.results }} />
            <PaginationService getSearch={getSearch} listSearch={listSearch} />
          </>
        ) : null}

        {(listSearch?.results?.length === 0 && !loading) && (
          <Box
            minHeight="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{ textAlign: 'center', color: colors.textBody, marginBottom: '100px' }}
              variant="h4"
            >
              Nenhum serviço encontrado
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
