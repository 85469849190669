import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './services/theme-service';
import Tags from './Components/MetaTags';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


Sentry.init({
  dsn: 'https://bf48b030c9c8488490cafae473b8e57f@o316139.ingest.sentry.io/6071230',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'portal',
  disableGlobal: true,
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Tags />
        <App />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
