import React, { useState, useEffect } from 'react';
import {
  Box, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { loadSignedTermSISECI } from '../../../../services/temosSISECI';
import { loadSignedTermSSO } from '../../../../services/temosSSO';
import dateFormat from '../../../../utils/dateFormat';
import PaginationService from '../PaginationService';
import TermoDialog from '../TermoDialog';
import getCookie from '../../../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    minHeight: '700px',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsList: {
    marginTop: '18px',
    width: '90%',
    border: '0.5px solid #DADCE0',
    maxWidth: '1052px',
    height: '96px',
    borderLeftWidth: '4px',
    borderLeftColor: `${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 35px',
    '&:last-child': {
      marginBottom: '50px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionsListText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
    color: '#373F41',
  },
  boxDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  date: {
    marginRight: '20px',
  },
  dateMobile: {
    marginRight: '50px',
  },
  textPrimary: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#373F41',
  },
  textSecundary: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#373F41',
  },
}));

interface Iterm {
  ativo: boolean;
  id: number
  texto: string;
  titulo: string;
  versao: string;
}
interface ItermsTerms {
  assinado_em: string;
  id: string;
  user: string;
  termo: Iterm;
}
interface ItermsResult {
  count: number;
  current: number;
  next?: number;
  page_size: number;
  page_size_query: string;
  previous?: number;
  query_param: string;
  results: ItermsTerms[];
  total_pages: number;
}

export default function TermsList(): JSX.Element {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const [termSelected, setTermSelected] = useState(null);
  const [open, setOpen] = useState<boolean>();
  const [termsSiseci, setTermsSiseci] = useState<ItermsResult>();
  const [termsSSO, setTermsSSO] = useState<ItermsResult>();
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');;
  const tokenSSO: string | null = getCookie('gov_access_token_sso');

  const getSignedTermSISECI = async (page = 1) => {
    try {
      const { data } = await loadSignedTermSISECI(tokenSSO, page);
      if (data?.results) {
        setTermsSiseci(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSignedTermSSO = async (page = 1) => {
    try {
      const { data } = await loadSignedTermSSO(tokenSSO, page);
      if (data?.results) {
        setTermsSSO(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenTermModal = (term: ItermsTerms) => {
    setTermSelected(term);
    setOpen(true);
  };

  useEffect(() => {
    if (tokenSiseci) {
      getSignedTermSISECI();
    }
    if (tokenSSO) {
      getSignedTermSSO();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSiseci, tokenSSO]);

  return (
    <>
      <Box className={classes.main}>

        {termsSiseci?.results.map((term) => (
          <Box className={classes.optionsList} key={term?.id} onClick={() => handleOpenTermModal(term)}>
            <Typography className={classes.optionsListText}>{term?.termo?.titulo}</Typography>
            <Box className={classes.boxDate}>
              <Box className={matches ? classes.dateMobile : classes.date}>
                <Typography className={classes.textPrimary}>Aceitado em</Typography>
                <Typography className={classes.textSecundary}>{term?.assinado_em && dateFormat(term?.assinado_em, true)}</Typography>
              </Box>
              <ArrowForward style={{ color: '#0F6FB7' }} />
            </Box>
          </Box>
        ))}
        {termsSSO?.results.map((term) => (
          <Box className={classes.optionsList} key={term?.id} onClick={() => handleOpenTermModal(term)}>
            <Typography className={classes.optionsListText}>{term?.termo?.titulo}</Typography>
            <Box className={classes.boxDate}>
              <Box className={matches ? classes.dateMobile : classes.date}>
                <Typography className={classes.textPrimary}>Aceitado em</Typography>
                <Typography className={classes.textSecundary}>{term?.assinado_em && dateFormat(term?.assinado_em, true)}</Typography>
              </Box>
              <ArrowForward style={{ color: '#0F6FB7' }} />
            </Box>
          </Box>
        ))}
        {termsSiseci?.results?.length ? (
          <PaginationService
            listTermsSiseci={termsSiseci}
            getSearchSSO={getSignedTermSSO}
            getSearchSiseci={getSignedTermSISECI}
          />
        ) : ''}
      </Box>
      {termsSiseci?.results?.length || termsSSO?.results?.length ? (
        <TermoDialog
          term={termSelected}
          setOpen={setOpen}
          open={open}
        />
        ) : ''}
    </>
  );
}
