import {
  Box, Button, Typography, useMediaQuery,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { BsPauseCircleFill, BsPlayCircleFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

interface VideoProps {
  titulo: string;
  resumo: string;
  video_id: string;
}
interface Props {
  videoList: VideoProps[];
}

export default function Videos({
  videoList,
}: Props): JSX.Element {
  const [videoId, setVideoId] = useState<string>(videoList[0].video_id);
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.dividerColor}`,
      padding: '0px 16px',
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(36),
      textTransform: 'uppercase',
      textAlign: 'left',
      marginBottom: 10,
      fontWeight: 800,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubTitle: {
      color: colors.subTitleColor,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    videoList: {
      flex: 2,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 10,
    },
    videoItem: {
      width: '100%',
      height: 104,
      padding: '19px 51px 19px 16px',
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      color: colors.textBody,
      borderRadius: 4,
      cursor: 'pointer',
      borderBottom: '4px solid rgba(26, 26, 26, 0.7)',
    },
    videoItemSelected: {
      borderColor: colors.accentColor,
    },
    iconActionVideo: {
      color: colors.textAccentColor,
      width: 40,
      height: 40,
    },
    titleVideo: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
      color: colors.textAccentColor,
      lineHeight: '22.4px',
    },
    img: {
      borderRadius: 4,
    },
    buttonMoreVideos: {
      alignSelf: 'center',
      marginTop: 40,
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '24px',
      padding: '8px 39px',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
      },
    },
    boxVideos: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  }));
  const matches = useMediaQuery('(max-width:1032px)');
  const classes = useStyles();
  const history = useHistory();

  const renderVideoMain = () => (
    <Box style={{
      height: 350,
      width: '100%',
      flex: 3,
    }}
    >
      <iframe
        style={{ width: '100%' }}
        height="350"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );

  useEffect(() => () => {
    renderVideoMain();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [videoId]);

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1040px"
        width="100%"
        flexDirection="column"
        padding="64px 0px"
      >
        <Box marginBottom="48px">
          <Typography
            className={classes.textTitle}
            variant="h2"
          >
            Vídeos
          </Typography>
          <Typography className={classes.textSubTitle}>
            Fique sempre atualizado com nossos vídeos.
          </Typography>
        </Box>
        <Box className={classes.boxVideos}>
          {renderVideoMain()}
          <Box
            className={classes.videoList}
          >
            {videoList.slice(0, 3).map((video) => (
              <Box
                key={`key-${video.video_id}`}
                className={videoId === video.video_id ? `${classes.videoItem} ${classes.videoItemSelected}` : classes.videoItem}
                onClick={() => setVideoId(video.video_id)}
                style={{
                  backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.7), rgba(26, 26, 26, 0.7)), url("https://img.youtube.com/vi/${video.video_id}/0.jpg")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div>
                  {videoId === video.video_id ? <BsPauseCircleFill className={classes.iconActionVideo} /> : <BsPlayCircleFill className={classes.iconActionVideo} />}
                </div>
                <Typography className={classes.titleVideo} variant="h3">{video.titulo}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          className={classes.buttonMoreVideos}
          onClick={() => history.push('/videos')}
        >
          Ver todos
        </Button>
      </Box>
    </Box>
  );
}
