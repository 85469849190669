import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import {
  ArrowForward, DeleteOutline,
} from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import {
  MdClose, MdDeleteForever, MdEvent, MdSearch,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { SearchI, SearchII } from '../../../SearchService';
// eslint-disable-next-line import/extensions
import { anexarArquivo, atenderOnline, checarHorarios } from '../../../../services/atendimentos';
import { searchService } from '../../../../services/servico';
import { UserData } from '../../../../services/user';
import getCookie from '../../../../utils/getCookies';
import { Schedule } from '../../../ServiceInfo/components/ModalAttendancePresential';

interface Props {
  open: boolean;
  handleClose: () => void;
}

interface ScheduleUser {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  prioridade: boolean;
  servico: number;
  setor: number;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: '16px',
    fontStyle: 'Molish',
    color: '#373F41',
    marginTop: 10,
    fontWeight: 700,
    lineHeight: '20px',
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  textSchedule: {
    color: '#373F41',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: '#373F41',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: '#9E9E9E',
  },
  rootUpload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 140,
    flexDirection: 'column',
  },
  adornedEnd: {
    paddingRight: 0,
  },
  inputSearch: {
    maxWidth: 582,
    width: '100%',
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 'none',
    paddingLeft: 16,
    fontFamily: 'Mulish',
    fontSize: 14,
    borderRadius: 0,
  },
  optionsList: {
    marginTop: '16px',
    width: '100%',
    border: '0.5px solid #DADCE0',
    maxWidth: '540px',
    height: '48px',
    borderLeftWidth: '2px',
    borderLeftColor: `${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionsListText: {
    fontSize: '14px',
    color: '#373F41',
    fontWeight: 600,
    fontFamily: 'Mulish',
  },
  boxnNextOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
  serviceSelectedTextTitle: {
    color: 'rgba(55,63,65,0.75)',
    fontWeight: 700,
    fontFamily: 'Mulish',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '18px',
  },
  serviceSelectedText: {
    color: '#373F41',
    fontWeight: 600,
    fontFamily: 'Mulish',
    fontSize: '16px',
    marginTop: '16px',
  },
  serviceSelectedSubText: {
    color: '#373F41',
    fontFamily: 'Mulish',
    fontWeight: 300,
    fontSize: '16px',
  },
  buttonAddArchives: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: '40px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#0A5D9C',
    },
  },
  boxConfirmScheduling: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  buttonConfirmScheduling: {
    backgroundColor: 'rgba(60, 210, 120, 0.7)',
    borderRadius: 0,
    width: '300px',
    '&:hover': {
      backgroundColor: '#3CD278',
    },
  },
  multilineColor: {
    color: '#000',
  },
}));

export default function ModalShedulingPresential({
  open,
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const [unitySelected, setUnitySelected] = useState < string >('');
  const [scheduleServiceUser, setScheduleServiceUser] = useState < ScheduleUser | string | undefined >();
  const [loading, setLoading] = useState < boolean >(false);
  const matches = useMediaQuery('(min-width:700px)');
  const [files, setFiles] = useState < FileObject[] >([]);
  const [typeSelected, setTypeSelected] = useState < string >('Chat');
  const [openUploads, setOpenUploads] = useState < boolean >(false);
  const [listSearch, setListSearch] = useState<SearchII>();
  const [serviceSelected, setServiceSelected] = useState<SearchI>();
  const [textSearch, setTextSearch] = useState<string>('');
  const [textHelp, setTextHelp] = useState < string >('');
  const [selectedDate, handleDateChange] = useState < Date >(new Date());
  const [scheduleSelected, setScheduleSelected] = useState < string >('');
  const [scheduleService, setScheduleService] = useState < string | Schedule | undefined >();

  const disableWeekends = (date: MaterialUiPickersDate): boolean => (date
    ? date.getDay() === 0 || date.getDay() === 6 || format(date, 'yyyy-MM-dd') === '2021-02-16'
    : false);

  const getSearch = async (search:string, page: number) => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await searchService(search, page);
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, serviceSelected.id);
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService('Algo inesperado ocorreu');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      getSearch(textSearch, 1);
    }
  };

  const handleGetServiceSelected = (service: SearchI) => {
    if (service) {
      setServiceSelected(service);
    } else {
      setServiceSelected(undefined);
    }
  };

  const handleRemoveServiceSelected = () => {
    setServiceSelected(undefined);
    setListSearch(undefined);
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = getCookie('gov_access_token_sso');
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;

    if (token && userData && serviceSelected) {
      try {
        const { data } = await atenderOnline(
          token,
          serviceSelected.id,
          unitySelected,
          textHelp.slice(0, 50),
          textHelp,
          serviceSelected.setor_id,
          typeSelected,
          selectedDate,
          scheduleSelected,
        );

        setScheduleServiceUser(data);
        if (data && files.length >= 1) {
          try {
            // eslint-disable-next-line no-restricted-syntax
            for (const fileUpload of files) {
              anexarArquivo(token, fileUpload.file.name, fileUpload.file.type, fileUpload.file, data.id);
            }
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            history.push(`/atendimento_online/${data.id}`);
          } catch (err) {
            console.log(err);
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          history.push(`/atendimento_online/${data.id}`);
        }
        setLoading(false);
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else {
          setScheduleServiceUser('Algo inesperado ocorreu.');
        }
        setLoading(false);
      }
    }
  };

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter((fileFiltering) => fileFiltering.data !== file.data);
    setFiles(fileFilter);
  };

  useEffect(() => {
    setTextSearch('');
    setListSearch(undefined);
    setUnitySelected(undefined);
    setTextHelp('');
    setScheduleServiceUser(undefined);
    setServiceSelected(undefined);
    setFiles([]);
    setOpenUploads(false);
    handleDateChange(new Date());
    setScheduleService(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          onClick={handleClose}
          className={classes.buttonClose}
        >
          <MdClose />
        </IconButton>

        <DialogTitle
          className={classes.header}
        >
          <Typography className={classes.textHeader}>
            <MdEvent style={{ marginRight: 15, width: 24, height: 24 }} />
            Agendamento rápido
          </Typography>
        </DialogTitle>
        {!serviceSelected ? (
          <DialogContent>
            <div id="input-unidade">
              <form
                style={{
                  display: 'flex',
                  maxWidth: 1052,
                  width: '100%',
                  justifyContent: 'center',
                  flexDirection: !matches ? 'column' : 'row',
                  alignItems: !matches ? 'center' : 'normal',
                  marginTop: '15px',
                }}
                onSubmit={handleSubmitSearch}
              >
                <input
                  onChange={(e) => {
                    setListSearch(undefined);
                    setTextSearch(e.target.value);
                  }}
                  className={classes.inputSearch}
                  placeholder="Digite aqui o que procura..."
                  value={textSearch}
                />
                <Button
                  style={{
                    borderRadius: 0,
                    marginTop: matches ? 0 : 20,
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <MdSearch style={{ fontSize: 24 }} />
                </Button>
              </form>
            </div>
            {loading
              ? <Box style={{ textAlign: 'center', marginTop: 10 }}><CircularProgress style={{ width: 25, height: 25, textAlign: 'center' }} color="secondary" /></Box>
              : ''}
            {listSearch?.results?.length === 0 && (
              <Typography
                className={classes.title}
              >
                {`Nenhum serviço encontrado para "${textSearch}"`}
              </Typography>
                )}
            {listSearch?.results.length ? (
              <>
                <Typography
                  className={classes.title}
                >
                  Serviços disponíveis
                </Typography>
                {listSearch.results.map((search) => (
                  <Box className={classes.optionsList} key={search.id} onClick={() => handleGetServiceSelected(search)}>
                    <Typography className={classes.optionsListText}>
                      {' '}
                      {search?.titulo}
                    </Typography>
                    <ArrowForward style={{ color: '#0F6FB7' }} />
                  </Box>
                ))}
              </>
            ) : ''}
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <Box className={classes.boxnNextOptions}>
                <Box>
                  <Typography className={classes.serviceSelectedTextTitle}>{serviceSelected?.categoria_nome}</Typography>
                  <Typography className={classes.serviceSelectedText}>{serviceSelected?.titulo}</Typography>
                  <Typography className={classes.serviceSelectedSubText}>
                    Órgão:
                    {serviceSelected?.orgao_nome}
                  </Typography>
                </Box>
                <Button onClick={handleRemoveServiceSelected}>
                  <DeleteOutline style={{ color: '#E53535' }} />
                </Button>
              </Box>
              <div id="input-unidade">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">Selecione a unidade</InputLabel>
                  <Select
                    error={typeof (scheduleServiceUser) === 'string'}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
              // value={age}
                    onChange={(event): void => {
                      setScheduleServiceUser(undefined);
                      setUnitySelected(event.target.value as string);
                    }}
                    label="Selecione a unidade"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {serviceSelected.servicosUnidade.map((unity) => (
                      <MenuItem
                        key={unity.id}
                        className={classes.menuItem}
                        value={unity.unidade.slug_unidade}
                      >
                        {unity.unidade.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  classes={{ root: classes.root }}
                  error={typeof (scheduleServiceUser) === 'string'}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">Como você quer ser atendido?</InputLabel>
                  <Select
                    error={typeof (scheduleServiceUser) === 'string'}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-tipo-chat"
              // value={age}
                    onChange={(event): void => {
                      setTypeSelected(event.target.value as string);
                    }}
                    label="Como você quer ser atendido?"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      value="Video"
                    >
                      Video e Chat
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      value="Chat"
                    >
                      Chat
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div id="input-data">
                <FormControl
                  classes={{ root: classes.root }}
                  error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
                  disabled={!unitySelected}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={false}
                      error={typeof (scheduleService) === 'string'
                  || typeof (scheduleServiceUser) === 'string'}
                      minDateMessage="Você não pode agendar em dias anteriores"
                      invalidDateMessage="A data informada é inválida"
                      minDate={new Date()}
                      InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                      autoOk
                      disabled={!unitySelected}
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: 'end', color: '#000' }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          handleDateChange(date);
                          if (unitySelected) {
                            getSchedulers(date, unitySelected);
                          }
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {typeof (scheduleService) === 'string' && (
                  <FormHelperText>{scheduleService}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div id="input-horario">
                <FormControl
                  classes={{ root: classes.root }}
                  disabled={!unitySelected || !selectedDate || typeof (scheduleService) === 'string'}
                  variant="outlined"
                  className={classes.formControl}
                  error={typeof (scheduleServiceUser) === 'string'}
                >
                  <InputLabel id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    classes={{
                      select: classes.menuItem,
                    }}
              // value={age}
                    onChange={(event): void => {
                      setScheduleSelected(event.target.value as string);
                    }}
                    label="Escolha o horário"
                  >
                    {typeof (scheduleService) !== 'string' && scheduleService?.horarios?.map((schedule) => (
                      <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                        {schedule}
                      </MenuItem>
                    ))}
                  </Select>
                  {typeof (scheduleServiceUser) === 'string' && (
                  <FormHelperText>{scheduleServiceUser}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <FormControl
                classes={{ root: classes.root }}
                disabled={!unitySelected}
                variant="outlined"
                className={classes.formControl}
                error={typeof (scheduleServiceUser) === 'string'}
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Como podemos ajudar?"
                  value={textHelp}
                  error={typeof (scheduleServiceUser) === 'string'}
                  disabled={!unitySelected || typeof (scheduleServiceUser) === 'string'}
                  onChange={(e) => setTextHelp(e.target.value)}
                  multiline
                  rows={4}
                  helperText={typeof (scheduleServiceUser) === 'string' ? scheduleServiceUser : ''}
                  variant="outlined"
                  InputProps={{
                    className: classes.multilineColor,
                  }}
                />
              </FormControl>
              <Button
                disabled={!unitySelected || typeof (scheduleServiceUser) === 'string'}
                onClick={(): void => setOpenUploads(true)}
                style={{
                  borderRadius: 0,
                  display: openUploads ? 'none' : 'block',
                  textTransform: 'none',
                  marginLeft: 10,
                }}
                variant="contained"
                color="primary"
              >
                Adicionar arquivos
              </Button>
              <div
                hidden={!openUploads}
                style={{
                  color: '#9E9E9E',
                  marginTop: 5,
                  marginLeft: 10,
                  width: '97%',
                }}
              >
                <DropzoneAreaBase
                  onAdd={(filesUpload) => {
                    setFiles(filesUpload);
                  }}
                  fileObjects={files}
                  acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                  showPreviewsInDropzone={false}
                  dropzoneText="Faça upload ou solte arquivos aqui"
                  showFileNames={false}
                  showFileNamesInPreview={false}
                  showAlerts={false}
                  filesLimit={10}
                  classes={{
                    icon: classes.iconUpload,
                    root: classes.rootUpload,
                  }}
                />
                {files.map((file, index) => (
                  <Card
                    style={{
                      width: '100%',
                      color: '#373F41',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0px 10px',
                      marginBottom: 2,
                    }}
                    key={index}
                  >
                    {file?.file?.name}
                    <IconButton onClick={(): void => removeFile(file)}>
                      <MdDeleteForever style={{ color: 'red', width: 24, height: 24 }} />
                    </IconButton>
                  </Card>
                ))}
              </div>

            </DialogContent>
            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 15,
              }}
            >
              <Button
                style={{
                  textTransform: 'none',
                  color: '#fff',
                  borderRadius: 0,
                  minWidth: 200,
                  marginTop: 20,
                }}
                color="secondary"
                disabled={
              !unitySelected
              || !textHelp
            }
                variant="contained"
                onClick={schedulerService}
              >
                {loading
                  ? <CircularProgress style={{ width: 25, height: 25 }} color="secondary" />
                  : 'Solicitar Atendimento'}
              </Button>
            </DialogActions>
          </>
        )}

      </Dialog>
    </>
  );
}
