import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { RouteLayout, ScrollToTop } from './Components';
import BlankLayout from './Components/Layouts/BlankLayout';
import WithHeader from './Components/Layouts/WithHeader';
import ColorContext from './Context/colorContext';
import {
  Accessibility,
  AttendanceOnlineChat,
  CategoryList,
  CategoyService,
  GovernorInfo,
  Home,
  IntegratedService,
  LoginCallback,
  MyAttendences,
  MyInformations,
  MySchedules,
  News,
  NotFound,
  OrganDetail,
  OrganListServices,
  OrgansList,
  QuickAccess,
  RatePortal,
  SchedulePrint,
  SeachService,
  ServiceByOrgan,
  ServiceInfo,
  Terms,
  VideoList,
  Workspace,
  newDetail,
} from './Pages';
import AppsGov from './Pages/AppsGov';
import DocumentValidation from './Pages/DocumentValidation';
import Pages from './Pages/Home/components/Pages';
import IntegrationServiceRedirect from './Pages/IntegrationServiceRedirect';
import Reschedule from './Pages/Reschedule';
import ServiceInfoPrint from './Pages/ServiceInfoPrint';
import ServiceList from './Pages/ServiceList';
import ServiceListByOrganization from './Pages/ServiceListByOrganization';
import ServicesProfileList from './Pages/ServicesProfileList';
import SiteMap from './Pages/SiteMap';
import SubjectService from './Pages/SubjectService';
import getCookie from './utils/getCookies';
import { setCookie } from './utils/setCookie';

interface ColorContextProps {
  accentColor: string;
  accentColorBackground: string;
  colorBackground: string;
  colorBackgroundSecundary: string;
  colorBackgroundTertiary: string;
  colorBackgroundQuaternary: string;
  colorBackgroundFooter: string;
  colorBackgroundFooterSecundary: string;
  colorBackgroundFooterTertiary: string;
  colorBackgroundNavSticky: string;
  colorBackgroundMainMenu: string;
  textSecondary: string;
  textBody: string;
  textAccentColor: string;
  textGrayStrong: string;
  textBlackGray: string;
  badgeColor: string;
  cardAccentColor: string;
  accordionTextColor: string;
  serviceCardBackgroundColor: string;
  favoriteBoxColor: string;
  breadcrumbButtonColorHover: string;
  borderShadowsColor: string;
  buttonSuccessColor: string;
  buttonErrorColor: string;
  accentColorSecondary: string;
  iconSocialColor: string;
  iconStepColor: string;
  stepLabelColor: string;
  menuItemColor: string;
  subTitleColor: string;
  dividerColor: string;
  boxShadowColor: string;
}

function App(): JSX.Element {
  const [typeTheme, setTypeTheme] = useState<string>('normal');

  const handleThemeColor = useCallback((type: string) => {
    setCookie('@portalunico/themeColor', type);
    setTypeTheme(type);
  }, []);

  const [colors, setColors] = useState<ColorContextProps>({
    accentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    accentColorBackground: atob(process.env.REACT_APP_COLOR_PRIMARY),
    colorBackground: atob(process.env.REACT_APP_COLOR_SECONDARY),
    colorBackgroundSecundary: '#ffffff',
    colorBackgroundTertiary: '#A7D046',
    colorBackgroundQuaternary: '#265AAA',
    colorBackgroundFooter: '#00519C',
    colorBackgroundFooterSecundary: '#043870',
    colorBackgroundFooterTertiary: '#73B0EE',
    colorBackgroundNavSticky: '#F8F8F8',
    colorBackgroundMainMenu: 'rgba(0, 57, 115, 0.95);',
    textSecondary: '#00519C',
    textBody: '#6B6D6E',
    textAccentColor: '#ffffff',
    textGrayStrong: '#5E5E5E',
    textBlackGray: '#666666',
    badgeColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    cardAccentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(136, 136, 136, 0.1)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: atob(process.env.REACT_APP_COLOR_SECONDARY),
    iconSocialColor: '#003F7E',
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#636363',
    dividerColor: '#E4E7F0',
    boxShadowColor: '0px 85px 34px rgba(60, 76, 112, 0.01), 0px 21px 21px rgba(60, 76, 112, 0.09)',
  });
  useEffect(() => {
    switch (typeTheme) {
      case 'normal':
        setColors({
          accentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
          accentColorBackground: atob(process.env.REACT_APP_COLOR_PRIMARY),
          colorBackground: '#ffffff',
          colorBackgroundSecundary: '#003F7E',
          colorBackgroundTertiary: '#A7D046',
          colorBackgroundQuaternary: '#265AAA',
          colorBackgroundFooter: '#00519C',
          colorBackgroundFooterSecundary: '#043870',
          colorBackgroundFooterTertiary: '#73B0EE',
          colorBackgroundNavSticky: '#F8F8F8',
          colorBackgroundMainMenu: 'rgba(0, 57, 115, 0.95);',
          textBody: '#333333',
          textSecondary: '#00519C',
          textAccentColor: '#ffffff',
          textGrayStrong: '#5E5E5E',
          textBlackGray: '#666666',
          badgeColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
          cardAccentColor: atob(process.env.REACT_APP_COLOR_PRIMARY),
          accordionTextColor: '#272727',
          serviceCardBackgroundColor: 'rgba(136, 136, 136, 0.1)',
          favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
          breadcrumbButtonColorHover: '#d4d4d4',
          borderShadowsColor: '#E1E6EF',
          buttonSuccessColor: '#3CD278',
          buttonErrorColor: '#F03D3D',
          accentColorSecondary: '#94C120',
          iconSocialColor: '#003F7E',
          iconStepColor: '#94C120',
          stepLabelColor: '#ccc',
          menuItemColor: '#d4d4d4',
          subTitleColor: '#636363',
          dividerColor: '#E4E7F0',
          boxShadowColor: '0px 85px 34px rgba(60, 76, 112, 0.01), 0px 21px 21px rgba(60, 76, 112, 0.09)',
        });
        break;

      case 'contraste':
        setColors({
          accentColor: '#FFFF1A',
          accentColorBackground: '#1F1F1F',
          colorBackground: '#121212',
          colorBackgroundSecundary: '#1F1F1F',
          colorBackgroundTertiary: '#1F1F1F',
          colorBackgroundQuaternary: '#1F1F1F',
          colorBackgroundFooter: '#1F1F1F',
          colorBackgroundFooterSecundary: '#121212',
          colorBackgroundFooterTertiary: '#FFFF1A',
          colorBackgroundNavSticky: '#121212',
          colorBackgroundMainMenu: 'rgba(31, 31, 31, 0.95);',
          textBody: '#FFFF1A',
          textSecondary: '#FFFF1A',
          textAccentColor: '#FFFF1A',
          textGrayStrong: '#FFFF1A',
          textBlackGray: '#FFFF1A',
          badgeColor: '#121212',
          cardAccentColor: '#1F1F1F',
          accordionTextColor: '#FFFF1A',
          serviceCardBackgroundColor: '#1F1F1F',
          favoriteBoxColor: '#1F1F1F',
          breadcrumbButtonColorHover: '#1F1F1F',
          borderShadowsColor: '#FFFF1A',
          buttonSuccessColor: '#FFFF1A',
          buttonErrorColor: '#FFFF1A',
          accentColorSecondary: '#FFFF1A',
          iconSocialColor: '#FFFF1A',
          iconStepColor: '#1F1F1F',
          stepLabelColor: '#ccc',
          menuItemColor: '#121212',
          subTitleColor: '#FFFF1A',
          dividerColor: '#FFFF1A',
          boxShadowColor: '0px 85px 34px rgba(60, 76, 112, 0.01), 0px 21px 21px rgba(60, 76, 112, 0.09)',
        });
        break;
      default:
        break;
    }
  }, [typeTheme]);

  useEffect(() => {
    const getThemeLocal = () => {
      const theme = getCookie('@portalunico/themeColor');
      const themeInLocal = theme || 'normal';
      setTypeTheme(themeInLocal);
    };

    getThemeLocal();
  }, []);

  return (
    <ColorContext.Provider value={{ colors, setTypeTheme: handleThemeColor, typeTheme }}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <RouteLayout
            path="/"
            exact
            component={Home}
            layout={WithHeader}
          />
          <RouteLayout
            path="/buscar/q=:slug"
            exact
            component={SeachService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico/:slug/presencial=:modal"
            exact
            component={ServiceInfo}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico-integrado/:slug/"
            exact
            component={IntegratedService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/categoria/:slug"
            exact
            component={CategoyService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/assuntos/:slug"
            exact
            component={SubjectService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/categorias"
            exact
            component={CategoryList}
            layout={WithHeader}
          />
          <RouteLayout
            path="/apps_governo"
            exact
            component={AppsGov}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servicos_destaques"
            exact
            component={ServicesProfileList}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servicos"
            exact
            component={ServiceList}
            layout={WithHeader}
          />
          <RouteLayout
            path="/meus_agendamentos"
            exact
            component={MySchedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/meus_atendimentos"
            exact
            component={MyAttendences}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout exact path="/pagina/:slug" layout={WithHeader} component={Pages} />
          <RouteLayout exact path="/agenda-governador" layout={WithHeader} component={GovernorInfo} />
          <RouteLayout exact path="/mapa-do-site" layout={WithHeader} component={SiteMap} />
          <Route path="/login/callback/" component={LoginCallback} />
          <RouteLayout exact path="/videos" layout={WithHeader} component={VideoList} />
          <RouteLayout exact path="/orgaos" layout={WithHeader} component={OrgansList} />
          <RouteLayout exact path="/orgao/:slug" layout={WithHeader} component={OrganDetail} />
          <RouteLayout exact path="/orgaos/servicos-por-orgao" layout={WithHeader} component={ServiceByOrgan} />
          <RouteLayout exact path="/orgao/:slug/:id" layout={WithHeader} component={OrganListServices} />
          <RouteLayout exact path="/orgao/:slug/servicos" layout={WithHeader} component={ServiceListByOrganization} />
          <RouteLayout exact path="/acessibilidade" layout={WithHeader} component={Accessibility} />
          <Route exact path="/agendamento/comprovante/:type?/:id" component={SchedulePrint} />
          <RouteLayout exact path="/validacao_documento" layout={WithHeader} component={DocumentValidation} />
          <RouteLayout
            exact
            path="/atendimento_online/:id"
            layout={WithHeader}
            component={AttendanceOnlineChat}
          />

          <RouteLayout exact path="/noticias" layout={WithHeader} component={News} />
          <RouteLayout exact path="/noticias/:slug" layout={WithHeader} component={newDetail} />

          <RouteLayout
            exact
            path="/workspace"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/termos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/permissoes"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/meus_agendamentos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/atividades"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area/privacidade"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area/meus-dados"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            path="/workspace/minha-area/meus-sistemas"
            exact
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/favoritos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/servico-digital"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/formularios/:slug"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/servico-digital/:tema_slug/:tema"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/atendimento_online/:id"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/seguranca"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            path="/workspace/minhas_solicitacoes"
            exact
            isAuth
            component={Workspace}
            layout={BlankLayout}
          />
          <RouteLayout
            path="/workspace/minhas_solicitacoes/view_doc/:uuid_doc/:identificador_solcitacao"
            exact
            isAuth
            component={Workspace}
            layout={BlankLayout}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_presencial/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_interno/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            path="/workspace/avaliar_atendimento_online/:ticket"
            exact
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_diario/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/avaliar_servico/:servico_slug/:perfil?"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/buscar_servico/avaliar"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/buscar_servico/avaliar/:servico_slug"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            path="/workspace/avaliar_fluxo/:id"
            exact
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            path="/workspace/qrcode/avaliacao/:ticket_qr/anonima"
            exact
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            path="/workspace/avaliacao/:slug/anonima"
            exact
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/acesso-rapido"
            isAuth
            layout={WithHeader}
            component={QuickAccess}
          />

          <RouteLayout
            exact
            path="/termos"
            isAuth
            layout={WithHeader}
            component={Terms}
          />
          <RouteLayout
            exact
            path="/meus_dados"
            isAuth
            layout={WithHeader}
            component={MyInformations}
          />
          <RouteLayout
            exact
            path="/avaliar_portal"
            layout={WithHeader}
            component={RatePortal}
          />
          <RouteLayout
            path="/reagendamentos/:url"
            exact
            component={Reschedule}
            layout={BlankLayout}
          />
          <RouteLayout
            path="/:category/:slug"
            exact
            component={ServiceInfo}
            layout={WithHeader}
          />
          <Route
            path="/:category/:slug/imprimir"
            exact
            component={ServiceInfoPrint}
          />

          <RouteLayout
            path="/servicos_integrados/:categoria/:slug/:servico_id/:integracao_id"
            exact
            isAuth
            layout={BlankLayout}
            component={IntegrationServiceRedirect}
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ColorContext.Provider>
  );
}

export default App;
