/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line import/no-cycle
import { News } from '../../Pages/News';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const adapterNewsLiferay = (newsData: any): News => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    const newsAdapter: News = {
      count: newsData.totalCount,
      current: newsData.page,
      next: (newsData.page as number) + 1,
      previous: newsData.lastPage,
      results: newsData.items.map((item) => ({
        autor_imagem: item.creator.name,
        categoria_icone: '',
        categoria_slug: '',
        categoria_titulo: '',
        conteudo: item.contentFields[1].contentFieldValue.data,
        created_at: item.datePublished,
        descricao_imagem: '',
        descricao_video: '',
        id: 12,
        imagem_destaque_url: `${atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_URL)}${item.contentFields[0].contentFieldValue.image.contentUrl}`,
        orgao_nome: '',
        orgao_sigla: '',
        orgao_slug: '',
        publicado: true,
        resumo: item.description,
        slug: item.friendlyUrlPath,
        subtopico_icone: '',
        subtopico_slug: '',
        subtopico_titulo: '',
        titulo: item.title,
        updated_at: item.dateModified,
        youtube_videoId: '',
      })),
      total_pages: newsData.pageSize,
    };
    return newsAdapter;
  }

  return newsData;
};

export default adapterNewsLiferay;
