import React, { useState } from 'react';
import {
  Box, Button, Card, Grid, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ScheduleHist, HistoryI } from '../..';
import PrintDialog from '../PrintDialog';
import CancelDialog from '../CancelDialog';
import dateFormatToBR from '../../../../utils/dateFormat';

interface Props {
  listSchedules: ScheduleHist;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    padding: 20,
    marginBottom: 40,
  },
  card: {
    width: '100%',
    minHeight: 120,
    color: '#373F41',
    padding: 28,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 25,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    paddingBottom: '5px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#373F41',
    padding: '10px 0px',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '24px',
    textDecorationLine: 'underline',
    color: '#373F41',
    paddingBottom: '10px',
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '24px',
    color: '#373F41',
  },
  textStatus: {
    paddingTop: '10px',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '24px',
    color: '#373F41',
    paddingBottom: '10px',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(32),
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: theme.palette.primary.main,
    paddingBottom: '10px',
  },
  textCounter: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '24px',
    textTansform: 'uppercase',
    color: '#373F41',
  },
  btnCancel: {
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    marginRight: '20px',
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
}));

export default function ServiceList({ listSchedules }: Props): JSX.Element {
  const classes = useStyles();
  const [scheduleSelected, setScheduleSelected] = useState < HistoryI > ();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const handlePrintDialog = (schedule: HistoryI) => {
    setOpenDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCancelDialog = (schedule: HistoryI) => {
    setOpenCancelDialog(true);
    setScheduleSelected(schedule);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScheduleSelected(undefined);
  };

  return (
    <>
      <Box className={classes.main}>
        <Box
          display="flex"
          maxWidth="1052px"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          flexDirection="column"
        >
          {listSchedules?.results?.map((schedule) => (
            <Card
              key={schedule.id}
              className={classes.card}
            >
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item sm={10}>
                  <Typography
                    className={classes.textUnidade}
                  >
                    {schedule.unidade.nome.toLowerCase()}
                  </Typography>
                  <Typography
                    className={classes.textService}
                  >
                    {schedule.servico.titulo}
                  </Typography>

                  <Typography
                    className={classes.textOrgan}
                  >
                    {`Órgão: ${schedule.orgao_responsavel}`}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    <Typography component="strong">Data:</Typography>
                    {` ${dateFormatToBR(schedule.data)}`}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    <Typography component="strong">Horário:</Typography>
                    {` ${schedule.hora.slice(0, 5)}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box style={{ width: '75px', textAlign: 'center', paddingTop: '25px' }}>
                    <Typography className={classes.textNumberCounter}>
                      {schedule.guiche.ordem}
                    </Typography>
                    <Typography className={classes.textCounter}>
                      GUICHÊ
                    </Typography>
                  </Box>

                </Grid>
              </Grid>
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item>
                  <Typography className={classes.textStatus}>
                    <Typography component="strong">{`${schedule.status}`}</Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {(schedule.status === 'Aguardando') && (
                    <Button variant="outlined" color="inherit" className={classes.btnCancel} onClick={() => handleCancelDialog(schedule)}>
                      <Typography>
                        Cancelar
                      </Typography>
                    </Button>
                  )}

                  <Button variant="outlined" color="primary" onClick={() => handlePrintDialog(schedule)}>
                    Imprimir
                  </Button>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Box>
      </Box>
      {scheduleSelected && (<PrintDialog open={openDialog} schedule={scheduleSelected} handleClose={handleCloseDialog} />)}
      {scheduleSelected && (<CancelDialog open={openCancelDialog} schedule={scheduleSelected} handleClose={handleCloseCancelDialog} />)}
    </>
  );
}
