import React from 'react';
import NavBarLink from '../QuickAccess/components/NavBarLink';
import { Breadcrumb } from './components';
import NavBarInfo from './components/NavBarInfo';
import TermsList from './components/TermsList';

export default function Terms(): JSX.Element {
  return (
    <>
      <Breadcrumb slug="Termos de aceite" />
      <NavBarLink />
      <NavBarInfo />
      <TermsList />
    </>
  );
}
