/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

type Props = {
  id?: string,
  value?: string,
  setValue?: (value: string) => void,
  onChange?: (value: string) => void,
  modeEdit?: 'basic' | 'full',
  addPlugins?: string[],
  addConf?: object,
  addToolbar?: string[],
  disabled?: boolean,
  print?: boolean,
};

const MyEditor = (props: Props) => {
  // const imageUploadHandle = async (blobInfo, success, failure) => {
  //     try {
  //         const { data } = await sendFile(blobInfo.blob());
  //         if (data) {
  //             success(data.arquivo || '');
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         failure('error');
  //     }
  // }

  const {
    value, id, setValue, print, addConf, addToolbar, disabled,
  } = props;

  return (
    <>
      <Editor
        tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
        value={value}
        id={id}
        onEditorChange={(content) => {
          setValue && setValue(content);
        }}
        init={{
          plugins: [
            'image',
            'accordion',
            'anchor',
            'media',
            'table',
            'link',
            'autolink',
            'autoresize',
            'charmap',
            'code',
            'codesample',
            'directionality',
            'emoticons',
            'fullscreen',
            'help',
            'importcss',
            'insertdatetime',
            'link',
            'lists',
            'nonbreaking',
            'pagebreak',
            'preview',
            'quickbars',
            'searchreplace',
            'visualblocks',
            'visualchars',
            'wordcount',
          ],
          // images_upload_handler: imageUploadHandle,
          toolbar: print ? ['print'] : 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | print | image | table | link  | wordcount',
          menubar: false,
          ...addConf,
          ...addToolbar,
        }}
        disabled={disabled}
      />
    </>
  );
};

export default MyEditor;
