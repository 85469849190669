import { AxiosResponse } from 'axios';
import { apiformflow, apisiseci } from './api';

export const getAllServices = (page: number): Promise<AxiosResponse> => apisiseci.get(`/cms/servicos_all/?page=${page}`);

export const getAllServicesList = (): Promise<AxiosResponse> => apisiseci.get('/cms/servicos_busca/?items_size=1000');

export const getService = (slug: string): Promise<AxiosResponse> => apisiseci
  .get(`/cms/servicos/?slug=${slug}`);

export const searchService = (slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos/?search=${slug.toUpperCase()}&ativo=true&page=${page}`);

export const searchServiceNew = (slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos_busca/?search=${slug.toUpperCase()}&page=${page}`);

export const searchServiceForOrgan = (
  slug: string,
  page?: number,
  search?: string,
): Promise<AxiosResponse> => {
  let url = `cms/servicos_busca/?orgao_slug=${slug}&ativo=true`;
  if (page) {
    url += `&page=${page}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return apisiseci.get(url);
};

export const serviceForCategory = (slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos/?categoria_slug=${slug}&ativo=true&page=${page}`);

export const getServiceTypeUser = (userType?: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública'): Promise<AxiosResponse> => apisiseci
  .get('/cms/serv_destaques/', {
    params: {
      publico: userType,
    },
  });

export const serviceSchedules = (): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos/?agendavel=true&ativo=true');

interface UnitScheduleParams {
  servico_id?: number;
  slug_unidade?: string;
}

export const unitSchedule = ({ servico_id, slug_unidade }: UnitScheduleParams): Promise<AxiosResponse> => 
  apisiseci.get(`/cms/unidades_geral/`, {
    params: {
      servico_id,
      slug_unidade
    }
  });

export const serviceSubjct = (topico_slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos/', {
    params: {
      topico_slug,
      page,
    },
  });

export const favoriteService = (servico: number, token: string): Promise<AxiosResponse> => apisiseci
  .post('/cms/servicos_favoritos/', {
    servico,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getFavoriteService = (token: string): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos_favoritos/',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

export const removeFavoriteService = (servico: number, token: string): Promise<AxiosResponse> => apisiseci
  .delete(`/cms/servicos_favoritos/${servico}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const ratingServiceAndComment = (service_id: number, avaliation: number, comments: string, token?: string): Promise<AxiosResponse> => apisiseci
  .post('cms/avaliar_servico/', {
    servicos: service_id,
    avaliacao: avaliation,
    comentario: comments,
  },
  {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });

export const ratingServiceInfo = (servico: number, avaliacao: string): Promise<AxiosResponse> => apisiseci.post('/cms/avaliacao_informacao/', {
  servico,
  avaliacao,
});

export const validateDocument = (id_code: string, verify_hash: string): Promise<AxiosResponse> => apiformflow
  .get(`/flow/validar-documento-resposta/?identificador=${id_code}&verificador=${verify_hash}`);

export const reportBug = (
  { token, conteudo, servico_id }:
  { token?: string,
    conteudo: string,
    servico_id: string},
): Promise<AxiosResponse> => apisiseci.post(
  '/cms/servicos_erros/',
  {
    conteudo,
    servico: servico_id,
  },
  {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  },
);

export const getIntegrationActive = (servico_id: number): Promise<AxiosResponse> => apisiseci
  .get('/cms/integracao/obter_ativa/', {
    params: {
      servico_id,
    },
  });

export const getIntegrationURL = (token: string, {
  servico_id,
  integracao_id,
  email,
}: {
  servico_id: number | string,
  integracao_id: string,
  email: string,
}): Promise<AxiosResponse> => apisiseci
  .post('/cms/integracao/iniciar/', {
    servico_id,
    integracao_id,
    email,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
