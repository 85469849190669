import {
  Box, Card, Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { OrgansII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listOrgans: OrgansII;
}

export default function OrgansList({
  listOrgans,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    subBox: {
      width: '100%',
      maxWidth: '1040px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        margin: '0px 16px',
      },
    },
    card: {
      width: '100%',
      minHeight: 154,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 4,
      padding: '24px 0px 24px 40px',
      backgroundColor: colors.colorBackground,
      borderLeft: `2px solid ${colors.accentColor}`,
      boxShadow: colors.boxShadowColor,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        textAlign: 'center',
        borderBottom: `2px solid ${colors.accentColor}`,
        borderLeft: 'none',
      },
      cursor: 'pointer',
    },
    textTitle: {
      fontWeight: 400,
      color: colors.subTitleColor,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
    },
    textSubTitle: {
      marginTop: 16,
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    iconStyle: {
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
      fontSize: 31,
    },
    boxInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    organUpdateText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: colors.subTitleColor,
      marginTop: 22,
    },
    link: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.main}>
      <Box
        className={classes.subBox}
      >
        {listOrgans.results.map((organ) => (
          <Link to={`/orgao/${organ.slug}`} key={organ.id} className={classes.link}>
            <Card className={classes.card}>
              <Box className={classes.boxInfo}>
                <Typography className={classes.textTitle}>{organ.sigla}</Typography>
                <Typography className={classes.textSubTitle}>{organ.nome}</Typography>
                <Typography className={classes.organUpdateText}>
                  Últimas modificações:
                  {' '}
                  {organ.updated_at ? format(new Date(organ.updated_at), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) : 'Nunca'}
                </Typography>
              </Box>
            </Card>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
