import React, { useContext } from 'react';
import {
  Box, Card, Link, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { News } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listNews: News;
}

export default function ServiceList({
  listNews,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    sectionInfo: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      marginTop: 48,
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 16px',
      },
    },
    card: {
      width: '100%',
      // maxHeight: 240,
      color: colors.textBody,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 12,
      cursor: 'pointer',
      background: colors.colorBackground,
      boxShadow: colors.boxShadowColor,
      '&:hover': {
        color: colors.accentColor,
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    content: {
      padding: '24px 98px 24px 32px',
      justifyContent: 'space-between',
      flexDirection: 'column',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        padding: '0px',
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      padding: '3px 6px',
      borderRadius: '6px',
      backgroundColor: '#316BBE',
      color: colors.textAccentColor,
      fontWeight: 700,
      lineHeight: '18.2px',
      width: 'min-content',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
        marginTop: '20px',
      },
    },
    title: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      maxWidth: 680,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(16),
        marginTop: 16,
        whiteSpace: 'normal',
        marginLeft: 10,
        marginRight: 10,
      },
    },
    organ: {
      fontWeight: 300,
      fontSize: theme.typography.pxToRem(16),
    },
    descriptionSubTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      wordBreak: 'break-word',
      maxHeight: 62,
      overflow: 'hidden',
      whiteSpace: 'pre-line',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
        maxHeight: '100%',
        marginTop: 16,
        marginLeft: 10,
        marginRight: 10,
      },
    },
    description: {
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
        marginBottom: 20,
      },
    },
    img: {
      width: 226,
      height: 226,
      objectFit: 'cover',
      borderRadius: '4px 0px 0px 4px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:660px)');

  const redirectToNew = (slug) => {
    history.push(`/noticias/${slug}`);
  };

  return (
    <Box className={classes.main}>
      <Box
        className={classes.sectionInfo}
      >
        {listNews.results.map((newItem) => (
          <Link
            onClick={(): void => redirectToNew(newItem.slug)}
            key={newItem.id}
            className={classes.card}
          >
            {newItem.imagem_destaque_url && (
              <img src={newItem.imagem_destaque_url} className={classes.img} alt="img notícia" />
            )}
            <Box
              className={classes.content}
            >
              <Typography
                className={classes.text}
              >
                {newItem?.subtopico_titulo}
              </Typography>
              <Typography className={classes.title}>
                {newItem.titulo}
              </Typography>
              <Box>
                <Typography className={classes.descriptionSubTitle} dangerouslySetInnerHTML={{ __html: newItem.resumo }} />
                {newItem.resumo.length >= 168 && <span>...</span>}
              </Box>
              <Typography className={classes.description}>
                Publicado:
                {' '}
                {format(new Date(newItem.created_at), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
              </Typography>
            </Box>

          </Link>
        ))}
      </Box>
    </Box>
  );
}
