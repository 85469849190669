import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci } from './api';

export const checarHorarios = (
  date: Date,
  unity: string,
  serviceID: number,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci
    .get(`atendimento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`);
};

export const atenderOnline = (
  token: string,
  servico: number,
  unidade: string,
  titulo: string,
  conteudo: string,
  setor: string,
  tipo: string,
  date: Date,
  hora: string,
  para_dependente?: boolean,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post('atendimento/solicitar/', {
    servico,
    unidade,
    titulo,
    conteudo,
    data,
    setor,
    tipo,
    hora,
    para_dependente,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const incluirDependente = (
  id_atendimento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string,
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post('atendimento/dependente/', {
    atendimento: id_atendimento,
    cpf,
    nome,
    data_nascimento: data,
    cpf_solicitante,
    grau_parentesco,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const anexarArquivo = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  topico: string,
  resposta?: string,
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('descricao', descricao);
  formData.append('arquivo', arquivo);
  formData.append('resposta', resposta || '');
  formData.append('topico', topico);
  return apisiseci.post('atendimento/anexar_topico/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const anexarArquivoResposta = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  resposta: string,
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('descricao', descricao);
  formData.append('arquivo', arquivo);
  formData.append('resposta', resposta);
  return apisiseci.post('atendimento/anexar_resposta/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const atendimentoPorId = (token: string, id: string): Promise<AxiosResponse> => apisiseci
  .get(`atendimento/meus_atendimentos/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

export const enviarMensagem = (token: string, id: string, message: string, haveAnexo?: boolean): Promise<AxiosResponse> => apisiseci
  .post('atendimento/responder/', {
    topico: id,
    conteudo: message,
    anexo: haveAnexo,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const salvarMotivosTopicos = (token: string, topico: string, descricao_problema?: string, motivo?: string): Promise<AxiosResponse> => apisiseci
  .post('atendimento/motivos_topico/', {
    topico,
    descricao_problema,
    motivo,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checarDisponibilidadeAgendamentos = (token: string, id: string, type?: string): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/checar_disponibilidade_unidade/?servico=${id}&tipo=${type}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checarIntervaloAgendamentos = (
  token: string,
  servico: string,
  tipo: string,
  unidade: string,
  data_inicio: string,
  data_fim: string,
): Promise<AxiosResponse> => apisiseci.get('agendamento/checar_intervalo', {
  params: {
    servico,
    tipo,
    unidade,
    data_inicio,
    data_fim,
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const meusAtendimentos = (token: string, page: number, params?: { status: string }): Promise<AxiosResponse> => apisiseci
  .get(`atendimento/meus_atendimentos/?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

export const cancelar = (token: string, id: string): Promise<AxiosResponse> => apisiseci
  .put(`atendimento/cancelar/${id}/`, {
    id,
    status: 'Cancelado',
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const mensagemLida = (token: string, resposta_id: number): Promise<AxiosResponse> => apisiseci
  .put(`atendimento/marcar_resposta/${resposta_id}/`, {
    lido: true,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
