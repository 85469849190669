/* eslint-disable linebreak-style */
import {
  Box, Collapse, IconButton, Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Close, ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { MenuListLinks } from '..';
import colorContext from '../../../../../Context/colorContext';
import { listAllOrgan } from '../../../../../services/orgao';

type Props = {
  open: boolean;
  title: string;
  categories: MenuListLinks[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface OrgansListProps {
  id: number;
  nome: string;
  sigla: string;
  slug: string;
}

export default function HoverMenuMobile({
  open, setOpen, title, categories,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 16px',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: 400,
      position: 'absolute',
      zIndex: 9999,
      backgroundColor: colors.colorBackgroundMainMenu,
    },
    container: {
      width: '100%',
      maxWidth: 1040,
      padding: '24px 16px',
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        flexDirection: 'row',
      },
    },
    boxCategory: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    boxUnity: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    boxLinksCategory: {
      columns: '2 auto',
      [theme.breakpoints.down('xs')]: {
        columns: '1',
      },
    },
    subMenuLink: {
      color: colors.textAccentColor,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 10,
      paddingLeft: theme.spacing(2),
    },
    linkCategory: {
      color: colors.textAccentColor,
      zIndex: 10000,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Open Sans',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textDecoration: 'none',
      marginBottom: 10,
    },
    link: {
      color: colors.textAccentColor,
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 24,
    },
    boxBtnClose: {
      display: 'flex',
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [organs, setOrgans] = useState<OrgansListProps[]>();

  const getAllOrgans = async () => {
    try {
      const response = await listAllOrgan();
      setOrgans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrgans();
  }, []);

  const handleonMouseLeave = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!open) {
    return null;
  }

  const handleToggleSubMenu = (id: string) => {
    setOpenSubMenu((prevOpen) => (prevOpen === id ? null : id));
  };

  const renderAlphabeticalOrdering = (a: OrgansListProps, b: OrgansListProps) => (a.nome.toLocaleLowerCase() > b.nome.toLocaleLowerCase() ? 1 : -1);

  return (
    <nav className={classes.main} onMouseLeave={handleonMouseLeave}>
      <Box>
        {categories
          && categories.map((category) => (
            <Box key={category.id}>
              {category.submenu_menu && category.submenu_menu.length > 0 ? (
                <Typography
                  className={classes.linkCategory}
                  onClick={() => handleToggleSubMenu(category.id)}
                  style={{ fontWeight: 'bold' }}
                >
                  {category.titulo}
                  {openSubMenu === category.id ? <ExpandLess /> : <ExpandMore />}
                </Typography>
              ) : (
                <a
                  href={category.link}
                  className={classes.linkCategory}
                  style={{ fontWeight: 'bold' }}
                >
                  {category.titulo}
                </a>
              )}
              <Collapse style={{ marginLeft: '10px' }} in={openSubMenu === category.id}>
                {category.submenu_menu.map((submenu) => (
                  <a
                    key={submenu.id}
                    href={submenu.pagina_slug ? `/pagina/${submenu.pagina_slug}` : submenu.link}
                    onClick={handleClose}
                    className={classes.linkCategory}
                    target={!submenu.abrir_na_pagina ? '_blank' : '_self'}
                    rel={!submenu.abrir_na_pagina ? 'noopener noreferrer' : ''}
                  >
                    {submenu.titulo}
                  </a>
                ))}
                {category.menu?.titulo && <Typography style={{ margin: '8px 0px', fontWeight: 'bold' }} className={classes.linkCategory}>{category.menu.titulo}</Typography>}
                {category?.menu?.submenu_menu.map((submenu) => (
                  <>
                    <a
                      key={submenu.id}
                      href={submenu.pagina_slug ? `/pagina/${submenu.pagina_slug}` : submenu.link}
                      onClick={handleClose}
                      className={classes.linkCategory}
                      target={!submenu.abrir_na_pagina ? '_blank' : '_self'}
                      rel={!submenu.abrir_na_pagina ? 'noopener noreferrer' : ''}
                    >
                      {submenu.titulo}
                    </a>
                  </>
                ))}
                <a
                  href="/orgaos/servicos-por-orgao"
                  className={classes.linkCategory}
                  onClick={() => handleToggleSubMenu('organs')}
                  style={{ fontWeight: 'bold' }}
                >
                  Serviços por Órgão
                </a>
                {organs.sort(renderAlphabeticalOrdering).map((submenu) => (
                  <a
                    key={submenu.id}
                    href={`/orgao/${submenu.slug}/${submenu.id}`}
                    onClick={handleClose}
                    className={classes.linkCategory}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {submenu.nome}
                  </a>
                ))}
              </Collapse>
            </Box>
          ))}
      </Box>
      <Box className={classes.boxBtnClose}>
        <IconButton onClick={() => setOpen(false)}>
          <Close style={{ color: colors.textAccentColor }} />
        </IconButton>
      </Box>
    </nav>
  );
}
