import React, { useEffect, useState, useContext } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Breadcrumb,
  Pagination,
  Title,
  VideoListComponent,
} from './components';
import colorContext from '../../Context/colorContext';
import { getVideos } from '../../services/videos';
import { VideoProps } from '../Home';

export default function VideoList(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<VideoProps>();
  const [page, setPage] = useState<number>(1);
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    async function handleVideoList() {
      try {
        setLoading(true);
        const { data } = await getVideos({ page });
        setVideos(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    handleVideoList();
  }, [page]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Vídeos" />
      <Title />
      {videos?.results?.length > 0 ? (
        <VideoListComponent videos={videos.results} />
      ) : (
        <>
          {!loading && (
          <Box
            marginTop="50px"
            marginBottom="60px"
            minHeight="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{ textAlign: 'center', color: colors.textBody }}
              variant="h4"
            >
              Nenhum vídeo encontrado!
            </Typography>
          </Box>
          )}
        </>
      )}
      {loading && (
        <Box
          marginTop="50px"
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {videos && (
      <Pagination
        getOrgans={setPage}
        videosList={videos}
      />
      )}
    </Box>
  );
}
