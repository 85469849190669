import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getCookie from '../../utils/getCookies';
import { getIntegrationURL } from '../../services/servico';

export interface Params {
  categoria: string,
  slug: string,
  servico_id: string,
  integracao_id: string,
}
function IntegrationServiceRedirect(): JSX.Element {
  const history = useHistory();
  const params: Params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getURLIntegration = async ({
    servico_id,
    integracao_id,
  }: {
    servico_id: number | string,
    integracao_id: string,
  }) => {
    try {
      const token: string | null = getCookie('gov_access_token_sso');
      const dataUser = JSON.parse(getCookie('gov_user_data'));
      const email = dataUser?.contato?.email;

      if (!token) {
        return;
      }
      setLoading(true);
      const { data } = await getIntegrationURL(token, {
        servico_id,
        integracao_id,
        email,
      });
      if (data?.link) {
        window.location.replace(data.link);
        setLoading(false);
        setError(false);
        return;
      }
      setLoading(false);
      setError(true);
    } catch (err) {
      console.log(err);
      setError(true);
      setLoading(false);
    }
  };

  const retry = async () => {
    if (params.servico_id && params.integracao_id) {
      await getURLIntegration({
        servico_id: params.servico_id,
        integracao_id: params.integracao_id,
      });
    }
  };

  const back = () => {
    history.push(`/${params.categoria}/${params.slug}`);
  };

  useEffect(() => {
    if (params.servico_id && params.integracao_id) {
      getURLIntegration({
        servico_id: params.servico_id,
        integracao_id: params.integracao_id,
      });
    }
  }, [params]);

  return (
    <>
      {loading && (
      <div style={{
        display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
      }}
      >
        <div><CircularProgress size={50} color="primary" /></div>
        <div><h1>Aguarde um momento você está sendo redirecionado para o serviço...</h1></div>
      </div>
      )}

      {error && (
      <div style={{
        display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
      }}
      >

        <div><h1>Falha ao redirecionar para o serviço, deseja tentar novamente? </h1></div>

        <div style={{
          marginBottom: 20,
        }}
        >
          <Button onClick={retry} variant="contained" color="primary" size="large">Tentar novamente</Button>
        </div>
        <div><Button onClick={back}>Voltar</Button></div>

      </div>
      )}

    </>
  );
}
export default IntegrationServiceRedirect;
