import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  ArrowForward, DeleteOutline,
} from '@material-ui/icons';
import {
  MdClose, MdEvent, MdDeleteForever,
} from 'react-icons/md';
import { IoMdSearch } from 'react-icons/io';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FileObject } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import { SearchI, SearchII } from '../../../SearchService';
import { agendar, checarHorarios } from '../../../../services/agendamento';
// eslint-disable-next-line import/extensions
import { UserData } from '../../../../services/user';
import { ES } from '../../../Home';
import { searchService } from '../../../../services/servico';
import getCookie from '../../../../utils/getCookies';

interface Props {
  open: boolean;
  handleClose: () => void;
}

interface Schedule {
  horarios: string[];
}

interface ScheduleUser {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  prioridade: boolean;
  servico: number;
  setor: number;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: string;
}

interface Holliday extends ES {
  results: [{
    agendamento: boolean;
    atendimento: boolean;
    data: string;
    id: number;
    titulo: string;
  }]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: '16px',
    fontStyle: 'Molish',
    color: '#373F41',
    marginTop: 10,
    fontWeight: 700,
    lineHeight: '20px',
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  textSchedule: {
    color: '#373F41',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: '#373F41',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: '#9E9E9E',
  },
  rootUpload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 140,
    flexDirection: 'column',
  },
  adornedEnd: {
    paddingRight: 0,
  },
  inputSearch: {
    maxWidth: 582,
    width: '100%',
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 'none',
    paddingLeft: 16,
    fontFamily: 'Mulish',
    fontSize: 14,
    borderRadius: 0,
  },
  optionsList: {
    marginTop: '16px',
    width: '100%',
    border: '0.5px solid #DADCE0',
    maxWidth: '540px',
    height: '48px',
    borderLeftWidth: '2px',
    borderLeftColor: `${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionsListText: {
    fontSize: '14px',
    color: '#373F41',
    fontWeight: 600,
    fontFamily: 'Mulish',
  },
  boxnNextOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
  serviceSelectedTextTitle: {
    color: 'rgba(55,63,65,0.75)',
    fontWeight: 700,
    fontFamily: 'Mulish',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '18px',
  },
  serviceSelectedText: {
    color: '#373F41',
    fontWeight: 600,
    fontFamily: 'Mulish',
    fontSize: '16px',
    marginTop: '16px',
  },
  serviceSelectedSubText: {
    color: '#373F41',
    fontFamily: 'Mulish',
    fontWeight: 300,
    fontSize: '16px',
  },
  buttonAddArchives: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: '40px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#0A5D9C',
    },
  },
  boxConfirmScheduling: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  buttonConfirmScheduling: {
    backgroundColor: 'rgba(60, 210, 120, 0.7)',
    borderRadius: 0,
    width: '300px',
    '&:hover': {
      backgroundColor: '#3CD278',
    },
  },
}));

export default function ModalAttendenceOnline({
  open,
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [serviceSelected, setServiceSelected] = useState<SearchI>();
  const [unitySelected, setUnitySelected] = useState < string >('');
  const [selectedDate, handleDateChange] = useState < Date >(new Date());
  const [scheduleSelected, setScheduleSelected] = useState < string >('');
  const [scheduleService, setScheduleService] = useState < string | Schedule | undefined >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState < ScheduleUser | string | undefined >();
  const [loading, setLoading] = useState < boolean >(false);
  const [listSearch, setListSearch] = useState<SearchII>();
  const [textSearch, setTextSearch] = useState<string>('');
  const matches = useMediaQuery('(min-width:700px)');

  const [files, setFiles] = useState < FileObject[] >([]);

  const getSearch = async (search:string, page: number) => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await searchService(search, page);
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const disableWeekends = (date: MaterialUiPickersDate): boolean => (date
    ? date.getDay() === 0 || date.getDay() === 6 || format(date, 'yyyy-MM-dd') === '2021-02-16'
    : false);

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, serviceSelected.id);
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService('Algo inesperado ocorreu');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const handleGetServiceSelected = (service: SearchI) => {
    if (service) {
      setServiceSelected(service);
    } else {
      setServiceSelected(undefined);
    }
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = getCookie('gov_access_token_sso');;
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      try {
        const { data } = await agendar(
          token,
          userData.cpf,
          serviceSelected.id,
          unitySelected,
          serviceSelected.setor_id,
          'Portal',
          selectedDate,
          scheduleSelected,
        );
        setScheduleServiceUser(data);
        // send files
        if (data && files.length >= 1) {
          try {
            // eslint-disable-next-line no-restricted-syntax
            for (const fileUpload of files) {
              const formData = new FormData();
              formData.append('titulo', 'atendimento presencial');
              formData.append('descricao', 'anexo atendimento presencial');
              formData.append('arquivo', fileUpload.file);
              // TODO: send for api
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoading(false);
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else {
          setScheduleServiceUser('Algo inesperado ocorreu.');
        }
        setLoading(false);
      }
    }
  };

  const handleRemoveServiceSelected = () => {
    setServiceSelected(undefined);
    setListSearch(undefined);
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      getSearch(textSearch, 1);
    }
  };

  useEffect(() => {
    setUnitySelected('');
    setScheduleSelected('');
    handleDateChange(new Date());
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    setFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          onClick={handleClose}
          className={classes.buttonClose}
        >
          <MdClose />
        </IconButton>
        <div hidden={typeof (scheduleServiceUser) === 'object'}>
          <DialogTitle
            className={classes.header}
          >
            <Typography className={classes.textHeader}>
              <MdEvent style={{ marginRight: 15, width: 24, height: 24 }} />
              Agendamento rápido
            </Typography>
          </DialogTitle>

          {!serviceSelected ? (
            <DialogContent>
              <div id="input-unidade">
                <form
                  style={{
                    display: 'flex',
                    maxWidth: 1052,
                    width: '100%',
                    justifyContent: 'center',
                    flexDirection: !matches ? 'column' : 'row',
                    alignItems: !matches ? 'center' : 'normal',
                    marginTop: '15px',
                  }}
                  onSubmit={handleSubmitSearch}
                >
                  <input
                    onChange={(e) => {
                      setListSearch(undefined);
                      setTextSearch(e.target.value);
                    }}
                    className={classes.inputSearch}
                    placeholder="Digite aqui o que procura..."
                    value={textSearch}
                  />
                  <Button
                    style={{
                      borderRadius: 0,
                      marginTop: matches ? 0 : 20,
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <IoMdSearch style={{ fontSize: 20 }} />
                  </Button>
                </form>
              </div>
              {loading
                ? <Box style={{ textAlign: 'center', marginTop: 10 }}><CircularProgress style={{ width: 25, height: 25, textAlign: 'center' }} color="secondary" /></Box>
                : ''}
              {listSearch?.results?.length === 0 && (
              <Typography
                className={classes.title}
              >
                {`Nenhum serviço encontrado para "${textSearch}"`}
              </Typography>
                )}
              {listSearch?.results.length > 0 ? (
                <>
                  <Typography
                    className={classes.title}
                  >
                    Serviços disponíveis
                  </Typography>

                  {listSearch.results.map((search) => (
                    <Box className={classes.optionsList} key={search.id} onClick={() => handleGetServiceSelected(search)}>
                      <Typography className={classes.optionsListText}>
                        {' '}
                        {search?.titulo}
                      </Typography>
                      <ArrowForward style={{ color: '#0F6FB7' }} />
                    </Box>
                  ))}
                </>
            ) : ''}
            </DialogContent>
          ) : (
            <DialogContent>
              <Box className={classes.boxnNextOptions}>
                <Box>
                  <Typography className={classes.serviceSelectedTextTitle}>{serviceSelected?.categoria_nome}</Typography>
                  <Typography className={classes.serviceSelectedText}>{serviceSelected?.titulo}</Typography>
                  <Typography className={classes.serviceSelectedSubText}>
                    Órgão:
                    {serviceSelected?.orgao_nome}
                  </Typography>
                </Box>
                <Button onClick={handleRemoveServiceSelected}>
                  <DeleteOutline style={{ color: '#E53535' }} />
                </Button>
              </Box>
              <div id="input-unidade">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">Selecione a unidade</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
              // value={age}
                    onChange={(event): void => {
                      setUnitySelected(event.target.value as string);
                      getSchedulers(selectedDate, event.target.value as string);
                    }}
                    label="Selecione a unidade"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {serviceSelected.servicosUnidade.map((unity) => (
                      <MenuItem
                        key={unity.id}
                        className={classes.menuItem}
                        value={unity.unidade.slug_unidade}
                      >
                        {unity.unidade.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div id="input-data">
                <FormControl
                  classes={{ root: classes.root }}
                  error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
                  disabled={!unitySelected}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={false}
                      error={typeof (scheduleService) === 'string'
                  || typeof (scheduleServiceUser) === 'string'}
                      minDateMessage="Você não pode agendar em dias anteriores"
                      invalidDateMessage="A data informada é inválida"
                      minDate={new Date()}
                      InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                      autoOk
                      disabled={!unitySelected}
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: 'end', color: '#000' }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          handleDateChange(date);
                          if (unitySelected) {
                            getSchedulers(date, unitySelected);
                          }
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {typeof (scheduleService) === 'string' && (
                  <FormHelperText>{scheduleService}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div id="input-horario">
                <FormControl
                  classes={{ root: classes.root }}
                  disabled={!unitySelected || !selectedDate || typeof (scheduleService) === 'string'}
                  variant="outlined"
                  className={classes.formControl}
                  error={typeof (scheduleServiceUser) === 'string'}
                >
                  <InputLabel id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    classes={{
                      select: classes.menuItem,
                    }}
              // value={age}
                    onChange={(event): void => {
                      setScheduleSelected(event.target.value as string);
                    }}
                    label="Escolha o horário"
                  >
                    {typeof (scheduleService) !== 'string' && scheduleService?.horarios.map((schedule) => (
                      <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                        {schedule}
                      </MenuItem>
                    ))}
                  </Select>
                  {typeof (scheduleServiceUser) === 'string' && (
                  <FormHelperText>{scheduleServiceUser}</FormHelperText>
                  )}
                </FormControl>

              </div>
              <Box className={classes.boxConfirmScheduling}>
                <Button
                  className={classes.buttonConfirmScheduling}
                  disabled={
                    !unitySelected
                    || !selectedDate
                    || !scheduleSelected
                    || typeof (scheduleService) === 'string'
                    || typeof (scheduleService) === 'undefined'
                  }
                  id="confirm-agendamento"
                  variant="contained"
                  onClick={schedulerService}
                >
                  {loading ? <CircularProgress style={{ width: 25, height: 25 }} color="secondary" /> : 'Confirmar Agendamento'}
                </Button>
              </Box>
            </DialogContent>
          )}

          {/*  aqui é a parte inicial da lista de serviços, só descomentar */}
        </div>

        <div hidden={typeof (scheduleServiceUser) === 'undefined' || typeof (scheduleServiceUser) === 'string'}>
          <DialogContent style={{ textAlign: 'center' }}>
            <Box>
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="80" height="80" rx="40" fill="#3CD278" fillOpacity="0.2" />
                <circle cx="40" cy="40" r="21" fill="white" />
                <path d="M40 15C26.2 15 15 26.2 15 40C15 53.8 26.2 65 40 65C53.8 65 65 53.8 65 40C65 26.2 53.8 15 40 15ZM35 52.5L22.5 40L26.025 36.475L35 45.425L53.975 26.45L57.5 30L35 52.5Z" fill="#3CD278" />
              </svg>
            </Box>
            <Typography className={classes.textSchedule}>
              Agendamento concluído, enviamos para você um comprovante, verifique no seu email
            </Typography>
            <Typography className={classes.textSubtitleSchedule}>
              Não esqueça de levar um documento com foto no dia marcado.
            </Typography>
            <Typography
              className={classes.textSubtitleSchedule}
              style={{ fontWeight: 600, marginTop: 30 }}
            >
              {format(selectedDate, 'dd/MM/yyyy')}
              {' '}
              às
              {' '}
              {scheduleSelected}
            </Typography>
          </DialogContent>
          <DialogActions style={{ marginTop: 10 }}>
            <Button
              style={{ color: '#373F41', border: 'none', textDecoration: 'underline' }}
              fullWidth
              variant="outlined"
              onClick={(): void => history.push('/meus_agendamentos')}
            >
              Ver agendamentos
            </Button>
            <Button
              onClick={handleClose}
              style={{ borderRadius: 0 }}
              fullWidth
              variant="contained"
              color="primary"
            >
              Voltar
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
