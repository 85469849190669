import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  titleBox: {
    textAlign: 'center',
    backgroundColor: '#0F6FB7',
    padding: 20,
  },
  boxTextTermo: {
    border: '1px solid #E3E5E7',
    padding: 10,
    height: '50vh',
    overflow: 'auto',
    color: '#000000',
  },
  footerTermo: {
    display: 'flex',
    padding: 20,
    justifyContent: 'space-between',
  },
  btnRecusar: {
    width: '100%',
    padding: 10,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  textRecusar: {
    textDecoration: 'underline',
  },
  btnAceitar: {
    backgroundColor: '#3CD278',
    color: '#FFFFFF',
    width: '45%',
    padding: 10,
    '&:hover': {
      backgroundColor: '#3CD278',
    },
  },
  btnAceitando: {
    backgroundColor: '#3CD278',
    color: '#FFFFFF',
    width: '100%',
    padding: 10,
    '&:hover': {
      backgroundColor: '#3CD278',
    },
  },
}));

export interface ITermSSO {
  id: string; titulo: string; versao: string; texto: string;
}
export interface ITermsSISECI{
  id: string; titulo: string; versao: string; texto: string;
}

interface Iterm {
  ativo: boolean;
  id: number
  texto: string;
  titulo: string;
  versao: string;
}
interface ItermsTerms {
  assinado_em: string;
  id: string;
  user: string;
  termo: Iterm;
}
interface Props {
  term: ItermsTerms;
  open: boolean,
  setOpen: (open: boolean) => void;
}

export default function TermoDialog({
  term,
  open,
  setOpen,
}: Props): JSX.Element {
  const classes = useStyles();
  const [termSelected, setTermSelected] = useState(null);
  const loadingSignTerm = false;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (term?.termo) {
      return setTermSelected(term?.termo);
    }
    return setTermSelected(undefined);
  }, [term]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleBox}>
          <Typography>
            TERMOS DE ACEITE
          </Typography>
        </DialogTitle>
        <DialogContent>

          <div className={classes.boxTextTermo}>
            {termSelected ? (
              <Box key={termSelected?.id}>
                <Typography variant="h4">
                  {termSelected?.titulo}
                  {' '}
                  - Versão (
                  {termSelected?.versao}
                  )
                </Typography>
                <Box dangerouslySetInnerHTML={{ __html: termSelected?.texto }} />
              </Box>
            )
              : ''}

          </div>
        </DialogContent>
        <Box className={classes.footerTermo}>

          {!loadingSignTerm
            ? (
              <>
                <Button onClick={handleClose} color="primary" className={classes.btnRecusar}>
                  <Typography className={classes.textRecusar}>Fechar</Typography>
                </Button>

                {/* { sisTermCurrent === 'SSO' ? (
                  <Button onClick={handleSignTermsSSO} color="primary" className={classes.btnAceitar} disabled={activeBtnSign}>
                    <Typography>Aceitar</Typography>
                  </Button>
                ) : ''} */}
                {/*
                { sisTermCurrent === 'SERVIÇOS' ? (
                  <Button onClick={handleSignTermsSISECI} color="primary" className={classes.btnAceitar} disabled={activeBtnSign}>
                    <Typography>Aceitar</Typography>
                  </Button>
                ) : ''} */}

              </>
            )
            : (
              <Button color="primary" className={classes.btnAceitando} disabled>
                <Typography>
                  Aceitando
                  {' '}
                  {'  '}
                  <CircularProgress size={10} />
                </Typography>
              </Button>
            )}

        </Box>
      </Dialog>
    </div>
  );
}
