import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

export default function PaginationService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
    },
    content: {
      maxWidth: '1040px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    iconTitle: {
      fontSize: 80,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: 40,
      },
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      color: colors.textAccentColor,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitle: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.textTitle}>
            Últimas notícias
          </Typography>
          <Typography className={classes.textSubtitle}>
            Confira as últimas notícias do estado.
          </Typography>
        </Box>
        <span className={`material-icons ${classes.iconTitle}`}>
          newspaper
        </span>
      </Box>
    </Box>
  );
}
