import React from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { ScheduleHist } from '../..';

interface Props {
  getSearch: (page: number) => void;
  listSearch: ScheduleHist;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginTop: 62,
    marginBottom: 100,
    '& .Mui-selected': {
      color: '#fff',
      borderRadius: 0,
    },
  },
  paginationItem: {
    color: '#9E9E9E',
  },
  paginationSelected: {
    color: '#fff',
  },
}));

export default function PaginationService({
  getSearch,
  listSearch,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ root: classes.paginationItem, selected: classes.paginationSelected }}
          />
        )}
        count={listSearch.total_pages}
        page={listSearch.current}
        onChange={(e, page) => getSearch(page)}
        color="primary"
      />
    </Box>
  );
}
